import { useEffect, useMemo, useState } from 'react';
import { SECTION_TYPES } from 'src/constants/scheduling.tsx';
import { isItemPreScheduling, itemIsValid } from 'src/helpers/scheduling/item.ts';
import { Item } from 'src/types/item.ts';
import { groupBy } from 'lodash';

export const usePreSchedulingItems = (items: Item[] | []) => {
  const [preSchedulingItems, setPreSchedulingItems] = useState<Item[]>([]);

  useEffect(() => {
    if (items.length > 0) {
      const filteredItems = items.filter((item) => isItemPreScheduling(item.status));
      setPreSchedulingItems(filteredItems);
    }
  }, [items]);

  return useMemo(() => {
    return groupBy(preSchedulingItems, (item) =>
      itemIsValid(item.status) ? SECTION_TYPES.VALID : SECTION_TYPES.PENDING,
    );
  }, [preSchedulingItems]);
};
