/* eslint-disable @typescript-eslint/no-misused-promises */
import { Icon, IconButton, Typography, BasicModal } from '@worthy-npm/worthy-common-ui-components';
import { Icon as CommonIcons } from '../Icons';
import {
    Content,
    FileInputWrap,
    Header,
    LargePreview,
    ModalContainer,
    SmallImagePreviewWrap,
    SmallPreview,
} from 'src/styles/photoUpload/photoUpload.styles.tsx';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { makeThumbnail } from 'src/helpers/common.ts';
import { updateItemPhotos } from 'src/store/itemsSlice';
import { useAppDispatch } from 'src/store';
import { PhotoDetails } from 'src/types/item.ts';

interface PhotoUploadProps {
    open: boolean;
    itemPublicId: string;
    onClose: (e: null, open: boolean, photoCount: number) => void;
    photos: PhotoDetails[];
}

interface PreviewType {
    status: 'new' | 'saved';
    previewUrl: string;
    url?: string; // actual url to delete
}

const largePreviewWidth = 720;

const PhotoUpload = ({ open, onClose, itemPublicId, photos }: PhotoUploadProps) => {
    const dispatch = useAppDispatch();

    const [ files, setFiles ] = useState<File[]>([]);
    const [ previews, setPreviews ] = useState<PreviewType[]>([]);
    const [ largePreview, setLargePreview ] = useState<string>('');
    const [ removedPhotos, setRemovedPhotos ] = useState<string[]>([]);

    const transformPhotosToPreviews = useCallback(() => {
        const transformedPreviews: PreviewType[] = photos.map((item) => ({
            status: 'saved',
            previewUrl: item.url,
            url: item.url,
        }));
        setPreviews(transformedPreviews);
        setFiles(transformedPreviews.map(() => new File([], 'saved')));
    }, [ photos ]);

    useEffect(() => {
        transformPhotosToPreviews();
    }, [ transformPhotosToPreviews ]);

    useEffect(() => {
        if (previews.length > 0) {
            setLargePreview(definePreviewMode(previews[0], true));
        } else {
            setLargePreview('');
        }
    }, [ previews ]);

    const handleFileUpload = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let selectedFiles = Array.from(event.target.files);

        if (selectedFiles.length > 6) {
            selectedFiles = selectedFiles.slice(0, 6);
        }

        const newPreviews: PreviewType[] = selectedFiles.map((file) => ({
            status: 'new',
            previewUrl: URL.createObjectURL(file),
        }));

        setFiles((prevFiles) => [ ...prevFiles, ...selectedFiles ]);
        setPreviews((prevPreviews) => [ ...prevPreviews, ...newPreviews ]);

        event.target.value = '';
    }, []);

    const handleRemoveImage = useCallback(
        (index: number) => {
            const removedImage = previews[index];
            if (removedImage.status === 'saved') {
                setRemovedPhotos((prev) => (removedImage.url ? [ ...prev, removedImage.url ] : prev));
            }

            URL.revokeObjectURL(removedImage.previewUrl);

            setPreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
            setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
            if (previews.length === 1 && removedImage.status === 'saved') {
                updateItemImages(true, removedImage.url ? [ removedImage.url ] : []);
            }
        },
        [ previews ],
    );

    const updateItemImages = useCallback( (empty?: boolean, leftovers: string[] = []) => {
        onClose(null, false, empty ? 0 : files.length);
        try {
            const actualFiles = files.filter((file) => file.size > 0);
            const toRemove = [...removedPhotos, ...leftovers];
            if (toRemove.length > 0 || actualFiles.length) {
                dispatch(updateItemPhotos({ filesToAdd: actualFiles, filesToRemove: toRemove, itemPublicId }));
            }

        } catch (err) {
            console.error(err);
        } finally {
            setFiles([]);
            setPreviews([]);
            setRemovedPhotos([]);
            setLargePreview('');
        }
    }, [ removedPhotos, files, itemPublicId, onClose ]);

    const definePreviewMode = (preview: PreviewType, large: boolean) => {
        if (preview.status === 'saved') {
            return large ? makeThumbnail(preview.previewUrl, largePreviewWidth) : makeThumbnail(preview.previewUrl);
        } else {
            return preview.previewUrl;
        }
    };

    const getFileInputComp = () => (
      <FileInputWrap
        className="add-more"
        accept=".jpeg,.png,.jpg"
        status="empty"
        onChange={ handleFileUpload }
        variant="filled"
        padding="7px 50px"
        color="paper"
        photos={ previews.length > 0 }
      >
        <CommonIcons.PlusIcon
          sx={{
            width: '16px',
            height: '16px',
          }}
        />
        <Typography
          variant="caption"
          textAlign="center"
          fontSize="14px"
          fontWeight="500"
          noWrap
        >
          Add photo
        </Typography>
      </FileInputWrap>
    );

    return (
        <BasicModal
            open={ open }
            onClose={ () => updateItemImages() }
        >
            <ModalContainer>
                <Header>
                    <Typography
                        variant="body1"
                        flex="1 1 auto"
                        textAlign="center"
                        marginLeft="30px"
                        fontWeight="700"
                    >
                      Item Photo Gallery
                    </Typography>
                    <IconButton
                        onClick={ () => updateItemImages() }
                        variant="default"
                        size="small"
                        color="secondary"
                        style={ { width: '24px', height: '24px' } }
                    >
                        <Icon.CloseIcon
                            data-automation="close"
                            style={ { width: '24px', height: '24px' } }
                        />
                    </IconButton>
                </Header>
                <Content photos={ previews.length > 0 }>
                    { previews.length > 0 && (
                        <>
                            <LargePreview
                                img={ largePreview }
                                actionHolder={ false }
                            />
                            <SmallImagePreviewWrap>
                                { previews.map((preview, index) => {
                                        const largePreviewMode = definePreviewMode(preview, true);
                                        return (
                                            <SmallPreview
                                                key={ index }
                                                img={ definePreviewMode(preview, false) }
                                                actionHolder={ false }
                                                icon={ <Icon.TrashWithCross /> }
                                                onPreview={ () => setLargePreview(largePreviewMode) }
                                                onClick={ () => handleRemoveImage(index) }
                                                largePreviewImg={ largePreviewMode === largePreview }
                                            />
                                        );
                                    },
                                ) }
                                { previews.length < 6 && (getFileInputComp()) }
                            </SmallImagePreviewWrap>
                        </>
                    ) }
                    { previews.length === 0 && (getFileInputComp()) }
                </Content>

                <Typography
                    variant="caption"
                    textAlign="center"
                    fontWeight="400"
                    fontSize="16px"
                    lineHeight="21px"
                    marginTop="12px"
                >
                  Adding additional photos helps us <br /> better understand your item’s value
                </Typography>
            </ModalContainer>
        </BasicModal>
    );
};

export default PhotoUpload;
