/* eslint-disable @typescript-eslint/no-misused-promises */
import {  Box, Button, CircularProgress, Icon, IconButton, Link, Stack, Typography } from "@worthy-npm/worthy-common-ui-components";
import _ from "lodash";
import { Item } from "src/types/item";
import { printLabel } from "../ItemCard/StepsActions/PickupStep";

const data= (item: Item)=> [{
    title: 'Print',
    describtion: <>
        print your shipment labels<br/>
        <Button disableRipple sx={{p: 0}} onClick={()=> printLabel(item)} disabled={!item?.schedulingDetails?.shipment?.shippingLabelUrl}>
            <Typography color={item?.schedulingDetails?.shipment?.shippingLabelUrl ? 'highlight.main': 'secondary.main'} fontSize={'0.8rem'} fontWeight={500}>
                <Link underline="none" color={'inherit'} fontSize={'1rem'} mr={2} >Print labels</Link>
                {!item?.schedulingDetails?.shipment?.shippingLabelUrl && <CircularProgress size={13} color="inherit" sx={{mr: 2}} />}
                <Icon.ExpandMoreShineIcon fontSize="inherit" transform="rotate(270)" color="inherit" />
            </Typography>
        </Button>
    </>,
},{
    title: 'Secure',
    describtion: <>
    Secure your valuable safety in a small container
    </>
},{
    title: 'Pack',
    describtion: <>
        Put the container and label in a box (Any box will do)
    </>
},{
    title: 'Seal and Attach',
    describtion: <>
        Seal the box and attach the FedEx label to the outside
    </>
}]

const DesktopStepper = ({item, onClose}: {item: Item, onClose?: () => void}) => {
    const listOfSteps = data(item);
  return (<Stack direction={'row'} position={'relative'} justifyContent={'space-between'}>
    <Typography p={2} variant="h3" maxWidth={200}>Getting Ready For {_.capitalize(item?.schedulingDetails.type || '')}</Typography>
    <Stack direction={'row'} gap={2} alignItems={'start'}>
        {listOfSteps.map((item, index) => (
            <Stack key={index} gap={1} maxWidth={150}>
                <Stack direction={'row'} alignItems={'flex-end'} >
                    <Typography variant="h3">{index + 1}</Typography>
                    {
                        index !== listOfSteps.length - 1 && (
                            <Typography sx={{margin: '0 auto', color: 'tritary.main'}}>
                                <Icon.NextArrowIcon sx={{width: '3rem'}} color="inherit"  />
                            </Typography>
                        )
                    }
                    
                </Stack>
                <Stack  direction={'column'} gap={1}>
                    <Typography variant="body1" fontWeight={'bold'}>{item.title}</Typography>
                    <Typography variant="body1" >{item.describtion}</Typography>
                </Stack>
            </Stack>
        ))}
    </Stack>
    {onClose && <Box position={'absolute'} top={(theme)=> theme.spacing(-1)} right={(theme)=> theme.spacing(-1)}>
        <IconButton size="small" color="secondary" onClick={onClose}>
                <Icon.CloseIcon fontSize={'small'} color="inherit" />
        </IconButton>
    </Box>}
  </Stack>);
};
export default DesktopStepper;
