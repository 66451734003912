import { Typography } from "@worthy-npm/worthy-common-ui-components";
import { Question } from "src/components/infoSections/faq";
import { FeesTable } from "src/views/FeesTable";



export  const MyItemQuestions: Question[] = [
    {
        q: 'What should I include in my shipping box?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                <ul>
                    <li>
                    Your shipping box should include your jewelry and any protective accessories it requires. If the piece you
                    would like to sell is from one of the{' '}
                    <a href="https://www.worthy.com/about/what-we-accept" target="_blank" rel="noreferrer">
                        <Typography component="span" variant="body1" color="tritary.main" display="inline-block">
                        jewelry or watch brands we accept
                        </Typography>
                    </a>
                    , please send it to us with any boxes or paperwork you have to help authenticate the item.
                    </li>
                    <li>
                    Please note: For non-branded items (those purchased from a local jeweler or retailer), do not include any
                    boxes or paperwork when shipping your piece. These boxes and paperwork will not be returned to you.
                    </li>
                </ul>
            </Typography>
        ),
    },{
        q: 'Does Worthy insure my jewelry?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
            Our&nbsp;
            <a href="https://www.worthy.com/about/terms-and-conditions" target="_blank" rel="noreferrer">
              <Typography component="span" variant="body1" color="tritary.main" display="inline-block">
                insurance coverage
              </Typography>
            </a>
            &nbsp; for a jewelry piece in transit is up to an amount of $50K<sup>*</sup> based on the resale replacement
            value and is subject to the terms, limitations and requirements of our then current insurance policy (the
            “Insurance Coverage”).
            <br />
            <br />
            <sup>*</sup> Jewelry pieces estimated above $50K will receive additional coverage with pre-approval from
            Worthy at our sole discretion.
            </Typography>
        ),
    },{
        q: 'Can I back out of the process after shipping?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                <ul>
            <li>
              You can request to have your piece sent back to you at any point prior to the auction. Once your&nbsp;
              <a href="https://www.worthy.com/faqs/pricing/#reserve-price">
                <Typography component="span" variant="body1" color="tritary.main" display="inline-block">
                  reserve price
                </Typography>
              </a>{' '}
              is set and the auction begins, you cannot request a return. If the auction ends and your reserve price is
              not met, you can have your piece sent back to you fully insured and free of charge.
            </li>
            <li>
              If you would like to have your jewelry returned to you, please contact our support team who will be able
              to assist you further with our return process.
            </li>
          </ul>
            </Typography>
        ),
    },{
        q: 'How long does the selling process take?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                The process typically takes 10-14 business days from the time your jewelry arrives at Worthy headquarters to
                getting paid.
            </Typography>
        ),
    },{
        q: 'When will I get a price estimate for my piece/s?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                In order to estimate the value of your jewelry we&apos;ll need to have your jewelry graded by a 3rd party
                grading lab following an in-house evaluation by our expert gemologists. This process will take place once
                your jewelry arrives in our offices and you will receive a recommended reserve price shortly after.
            </Typography>
        ),
    },{
        q: 'How much does your service cost?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                There is never an out of pocket cost to you. Our fees are subject to a successful auction and are based upon
                a percentage of the final sale price, as shown below. We share a common interest. We also only get paid when
                you do.
                <br />
                <span style={{ margin: '18px 0 12px', display: 'inline-block' }}>This is our fee table:</span>
                <br />
                <FeesTable />
            </Typography>
        ),
    },{
        q: 'What type of jewelry can I sell with you?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                <p>
              Worthy accepts jewelry pieces which will interest the professional buyers on our auction platform. We
              typically accept the following types of jewelry:
            </p>
            <ul>
              <li>
                <strong>Diamond Jewelry</strong> - This includes rings, bracelets, necklaces, and earrings made from
                gold or platinum.
              </li>
              <li>
                <strong>Designer Jewelry</strong> such as Tacori, Tiffany & Co, Cartier, Harry Winston,and Bvlgari.
              </li>
              <li>
                <strong>Branded watches</strong> - Luxury watches from brands including Rolex, Cartier, Omega,
                Breitling, Audemars Piguet, Patek Philippe, Tag Heuer, and many more.
              </li>
            </ul>
            <p>
              <a target="_blank" href="https://www.worthy.com/about/what-we-accept" rel="noreferrer">
                <Typography component="span" variant="body1" color="tritary.main" display="inline-block">
                  View full list
                </Typography>
              </a>
            </p>
            </Typography>
        ),
    },
];
