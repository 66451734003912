import { preScheduleStates, shippingStates, WorkflowStatus } from "src/constants/item.constants";
import { Item } from "src/types/item";


export const postScheduleItems = (items: Item[]) =>  items.filter((item) => {
    return !preScheduleStates.includes(item.status);
  });

export const disablePhotoUploadState = (state: WorkflowStatus) => {
  return ![...preScheduleStates, ...shippingStates].includes(state);
}