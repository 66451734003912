import styled from '@emotion/styled';
import { Accordion, Checkbox, Stack } from '@worthy-npm/worthy-common-ui-components';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: '1.5px solid',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.divider,
  '& .MuiButtonBase-root.MuiAccordionSummary-root': {
    minHeight: '0px',
    margin: theme.spacing(0, 0),
  },
  '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root ': {
    fontSize: '1rem',
    color: theme.palette.secondary.main,
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1, 0),
  },
}));

export const ValidItemCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.highlight.main,
  '&.Mui-checked': {
    color: theme.palette.highlight.main,
  },

  height: 24,
  padding: 0,
}));

export const DetailsCaptionWrap = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: '1',
  alignItems: 'center',
  color: theme.palette.secondary.main,

  [theme.breakpoints.down('sm')]: {
    gap: '4px 8px',
    flexWrap: 'wrap',
  }
}));
