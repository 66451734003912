import styled from '@emotion/styled';
import { Box, Typography } from '@worthy-npm/worthy-common-ui-components';
import { Icon } from 'src/components/Icons.tsx';
import TextField from '@worthy-npm/worthy-common-ui-components/components/TextField';

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {},
}));

const TitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
        gap: '8px',
    },
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '264px',
    textAlign: 'center',
    marginTop: 'auto',
    [theme.breakpoints.down('md')]: {},
}));

const SkipBtn = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    marginTop: '24px',
    [theme.breakpoints.down('md')]: {
        marginTop: '14px',
    },
}));

const StyledArrowIcon = styled(Icon.SlimArrow, {
    shouldForwardProp: (prop) => !['doneStep'].includes(prop),
})<{ doneStep?: boolean }>(({ theme }) => ({
    fontSize: 30,
    transform: 'rotate(180deg)',
    '& path': {
        stroke: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: 18,
    },
}));

const SurveyContainer = styled(Box, {
    shouldForwardProp: (prop) => !['expand', 'gem'].includes(prop),
})<{ expand?: boolean; gem?: boolean }>(({ theme, expand, gem }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '858px',
    height: '420px',
    maxWidth: '858px',
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '16px',
    gap: '12px',
    [theme.breakpoints.down('md')]: {
        height: expand ? '455px' : gem ? '500px' : '366px',
        minWidth: '326px',
        minHeight: gem ? '500px' : '365px',
        maxHeight: '455px',
        maxWidth: '326px',
    },
}));

const SurveyCardWrapper = styled(Box, {
    shouldForwardProp: (prop) => !['buttons', 'input', 'basic', 'gem'].includes(prop),
})<{ buttons?: boolean; input?: boolean, basic?: boolean, gem?: boolean }>(({ theme, buttons, input, basic, gem }) => ({
    display: 'flex',
    gap: gem || !basic ? '6px' : '2em',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
        gap: '6px',
    },
    ...(buttons && {
        justifyContent: 'space-between',
        flex: '1',
    }),
    ...(input && {
        gap: '58px',
    }),
}));

const Card = styled(Box, {
    shouldForwardProp: (prop) => !['selected'].includes(prop),
})<{ selected?: boolean }>(({ theme, selected }) => ({
    border: `1px solid ${theme.palette.divider}`,
    flex: '1',
    borderRadius: theme.shape.borderRadius,
    maxWidth: '128px',
    minWidth: '128px',
    width: '128px',
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: selected ? theme.palette.background.secondary : theme.palette.background.paper,
    color: selected ? theme.palette.background.paper : theme.palette.text.primary,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
        maxWidth: '143px',
        minWidth: '95px',
        height: '43px',
    },
}));

const CardWrapper = styled(Box, {
    shouldForwardProp: (prop) => !['space', 'gem'].includes(prop),
})<{ space?: boolean; gem?: boolean }>(({ theme, space, gem }) => ({
    padding: gem ? '0' : '24px 0',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
        gap: space ? '3px' : '8px',
        padding: '0',
    },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    // maxWidth: '326px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
        gap: '8px',
    },
}));

const InputWrapper = styled(Box, {
    shouldForwardProp: (prop) => !['textarea', 'expand', 'large', 'basic'].includes(prop),
})<{ textarea?: boolean; expand?: boolean, large?: boolean, basic?: boolean }>(({ theme, textarea, expand, large, basic }) => ({
    width: '56%',
    marginTop: '49px',

    ...(textarea && {
        width: '100%',
        marginTop: '29px',
    }),

    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: expand ? '49px' : '39px',
        ...(large && {
            marginTop: '7em',
        }),

        ...(textarea && !basic && {
            marginTop: '9px',
        }),
    },
}));

const Bullet = styled('span', {
    shouldForwardProp: (prop) => !['active'].includes(prop),
})<{ active?: boolean }>(({ theme, active }) => ({
    content: '""',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: active ? theme.palette.primary.main : theme.palette.divider,
    [theme.breakpoints.down('md')]: {
        width: '5px',
        height: '5px',
    },
}));

const BulletWrapper = styled(Box)(() => ({
    display: 'flex',
    gap: '3px',
    alignItems: 'center',
}));

const SurveyTitle = styled(Typography)(({ theme }) => ({
    fontWeight: '500',
    fontSize: '20px',
    paddingTop: '16px',
    [theme.breakpoints.down('md')]: {
        fontWeight: '700',
        fontSize: '15px',
        paddingTop: '0',
    },
}));

const HeaderDesc = styled(Typography)(({ theme }) => ({
    fontWeight: '400',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
        fontWeight: '300',
    },
}));

const NumberInput = styled(TextField)(() => ({
    '.MuiOutlinedInput-root': {
        borderRadius: 3,
    },
}));

export {
    SkipBtn,
    HeaderDesc,
    ButtonsContainer,
    InputWrapper,
    NumberInput,
    ButtonWrapper,
    SurveyTitle,
    BulletWrapper,
    Bullet,
    CardWrapper,
    Header,
    SurveyContainer,
    TitleContainer,
    StyledArrowIcon,
    SurveyCardWrapper,
    Card,
};
