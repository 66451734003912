import React from 'react';
import { DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';

interface InTransitStepProps {
  item: Item;
}

const InTransitStep: React.FC<InTransitStepProps> = () => {
  return (
    <StyledStack>
      <DescriptionText>
        Once delivered, we will prepare your item for auction
      </DescriptionText>
    </StyledStack>
  );
};

export default InTransitStep;
