import { useMediaQuery } from '@worthy-npm/worthy-common-ui-components';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';

export const useMobileVersion = () => {
    return useMediaQuery<typeof worthyShineTheme>(() => worthyShineTheme.breakpoints.only('xs'))
}

export const useTabletVersion = () => {
    return useMediaQuery<typeof worthyShineTheme>(() => worthyShineTheme.breakpoints.only('sm'))
}

export const useDesktopVersion = () => {
    return useMediaQuery<typeof worthyShineTheme>(() => worthyShineTheme.breakpoints.up('md'))
}
