import { createBrowserRouter, Navigate } from 'react-router-dom';
import Error from './pages/Error';
import Shipping from 'src/pages/shipping.tsx';
import Protected from 'src/pages/protected.tsx';
import MyItems from './pages/MyItems';

export const routes = [
  {
    element: <Protected />,
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/shipping"
            replace
          />
        ),
        errorElement: <Error />,
      },
      {
        path: '/shipping',
        element: <Shipping />,
        errorElement: <Error />,
      },
      {
        path: '/my-items',
        element: <MyItems />,
        errorElement: <Error />,
      },
    ],
  },
];

// https://reactrouter.com/en/main/routers/create-browser-router
const router = createBrowserRouter(routes);

export default router;
