const LoadingDiamondAnimation = '/loaders/loader_off_white.gif';

export function LoadingDiamondImg() {
  return (
    <img
      data-automation="loading-overlay"
      src={LoadingDiamondAnimation}
      alt="loading"
    />
  );
}
