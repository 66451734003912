import { getGoogleApiKey } from 'src/config';

export const loadGoogleMapsApi = () => {
    const apiKey = getGoogleApiKey();

    if (!apiKey) {
        console.error('Google Maps API key is missing');
    } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        document.head.appendChild(script);
    }
};
