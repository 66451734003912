import { HIWProps } from 'src/components/infoSections/hiw.tsx';
import submit from '/img/granSubmit.jpeg';

const StepsData = [
    {
        title: 'Print',
        description: 'Print your shipping labels and insurance information',
        img: submit,

    },
    {
        title: 'Secure',
        description: 'Secure your item safely in a small container',
        img: submit,

    },
    {
        title: 'Pack',
        description: 'Put the container and inner label in a box (a used box will do)',
        img: submit,
    },
    {
        title: 'Seal',
        description: 'Seal the box and attached the FedEx label to the outside',
        img: submit,
    },
];


export const HIWForPrepareForDropOffPropsData: HIWProps = {
    steps: StepsData,
    title: 'Preparing for Drop Off',
    currentStepIndex: -1,
};
