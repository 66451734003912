import { StyledNavToolBar, TabsContainer } from 'src/styles/nav/navigation.styles.tsx';
import { Link, useLocation } from 'react-router-dom';
import { Tab } from '@worthy-npm/worthy-common-ui-components';
import { routes } from 'src/routes.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import React, { useEffect, useState } from 'react';
import { getRoute, setRoute } from 'src/store/sharedSlice';
import { createTempShippingItem, selectError } from 'src/store/itemsSlice';
import { useDesktopVersion } from 'src/helpers/deviceSize.ts';
import {
    getReset,
    getSelectedItems,
    getSelectedShippingMethod,
    getStreet,
    resetShippingState,
} from 'src/store/schedulingSlice';

const Navigation = () => {
    const location = useLocation();
    const rootPath = useAppSelector(getRoute);
    const error = useAppSelector(selectError);
    const selectedScheduled = useAppSelector(getSelectedItems);
    const shipmentMethod = useAppSelector(getSelectedShippingMethod);
    const street = useAppSelector(getStreet);
    const needReset = useAppSelector(getReset);
    const dispatch = useAppDispatch();
    const [currentPath, setCurrentPath] = useState(rootPath);
    const tabRoutes = routes[0].children.map((route) => route.path);
    const filteredRoutes = tabRoutes.filter((route) => route !== '/');
    const isPc = useDesktopVersion();

    useEffect(() => {
        if (location.pathname !== rootPath) {
            setCurrentPath(location.pathname);
        }
    }, [rootPath, location.pathname]);

    const handleTabChange = (event: React.SyntheticEvent<Element>, newValue: string) => {
        setCurrentPath(newValue);
        dispatch(setRoute(newValue));
        if (needReset) {
            dispatch(createTempShippingItem({ publicIds: selectedScheduled, shipType: shipmentMethod, location: street }));
            dispatch(resetShippingState());
        }
    };

    return (
        <StyledNavToolBar position="sticky">
            <TabsContainer
                value={currentPath}
                variant={ isPc ? 'standard' : 'fullWidth' }
                selectionFollowsFocus
                centered
                onChange={handleTabChange}
            >
                {filteredRoutes.map((route) => (
                    <Tab
                        key={route}
                        className="tab-wrapper"
                        label={route === '/shipping' ? 'Shipping' : 'My Items'}
                        value={route}
                        to={route}
                        component={Link}
                        disabled={!!error}
                    />
                ))}
            </TabsContainer>
        </StyledNavToolBar>
    );
};

export default Navigation;
