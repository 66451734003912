import {
    ButtonWrapper,
    Card,
    CardWrapper,
    InputWrapper,
    NumberInput,
    SurveyCardWrapper,
    SurveyTitle,
} from 'src/styles/survey/survey.styles.tsx';
import { Button, Typography } from '@worthy-npm/worthy-common-ui-components';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

interface ISurvey {
    title: string;
    type: string;
    scope: string;
    options: string[];
    placeholder?: string;
    onNext: (value: string) => void;
    onFlowSelect: (value: 'gia' | 'additional') => void;
    flow: 'gia' | 'additional' | '';
    isGem: boolean;
}

const SurveyConstructor = (props: ISurvey) => {
    const { title, options, type, scope, onNext, placeholder, flow } = props;
    const [selected, setSelected] = useState<string>('');

    useEffect(() => {
        setSelected('');
    }, [title]);

    const handleNext = (option: string) => {
        setSelected(option);
        onNext(option);
    };

    const onFlowSelect = (option: string, idx?: number) => {
        setSelected(option);
        // define next question scope
        props.onFlowSelect(idx === 0 ? 'additional' : 'gia');
        return;
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let cleanInput = e.target.value;
        setSelected(e.target.value);

        if (type === 'textarea') {
            onNext(cleanInput);
            return;
        }

        if (scope === 'additional') {
            cleanInput = cleanInput.replace(',', '.');
            cleanInput = cleanInput.replace(/[^0-9.]/g, '');
            if (cleanInput.includes('.')) {
                const [integerPart, decimalPart] = cleanInput.split('.');
                cleanInput = `${integerPart}.${decimalPart.substring(0, 2)}`;
            }
            const numericValue = parseFloat(cleanInput);
            if (!isNaN(numericValue) && numericValue > 30) {
                cleanInput =
                    '30' +
                    (cleanInput.includes('.') ? cleanInput.substring(cleanInput.indexOf('.')) : '');
            }

            setSelected(cleanInput);
            onNext(cleanInput);
            return;
        }

        if (scope === 'gia') {
            cleanInput = e.target.value.replace(/\D/g, '');
            const limitedInput = cleanInput.slice(0, 10);
            setSelected(limitedInput);
            e.target.value.length >= 6 && onNext(limitedInput);
        }

        if (selected.length !== e.target.value.length && e.target.value.length < 6) {
            onNext('');
        }
    };

    return (
        <SurveyCardWrapper
            buttons={type === 'button'}
            input={type === 'input' && placeholder?.includes('carat')}
            basic={type === 'select' && scope.includes('basic')}
            gem={props.isGem}
        >
            <SurveyTitle
                textAlign="center"
                variant="caption"
            >
                {title}
            </SurveyTitle>
            {type === 'select' && (
                <CardWrapper
                    data-automation="constructor-type-select"
                    space={options.length > 4}
                    gem={props.isGem}
                >
                    {options.map((option, index) => (
                        <Card
                            data-automation={`${_.kebabCase(option)}`}
                            onClick={() => handleNext(option)}
                            selected={selected === option}
                            key={index}
                        >
                            <Typography
                                variant="caption"
                                fontWeight="400"
                            >
                                {option}
                            </Typography>
                        </Card>
                    ))}
                </CardWrapper>
            )}

            {type === 'button' && (
                <ButtonWrapper data-automation="constructor-type-btn">
                    {options.map((option, index) => (
                        <Button
                            data-automation={`${_.kebabCase(option)}`}
                            key={index}
                            fullWidth
                            variant={index === 0 ? 'outlined' : 'contained'}
                            disableElevation
                            color={index === 0 ? 'inherit' : 'highlight'}
                            size="large"
                            onClick={() => onFlowSelect(option, index)}
                        >
                            {option}
                        </Button>
                    ))}
                </ButtonWrapper>
            )}
            {type === 'input' && (
                <InputWrapper
                    data-automation="constructor-type-input"
                    large={type === 'input' && placeholder?.includes('carat')}
                >
                    <NumberInput
                        data-automation="constructor-input"
                        placeholder={placeholder}
                        variant="standard"
                        fullWidth
                        onChange={onInputChange}
                        value={selected}
                    />
                </InputWrapper>
            )}
            { type === 'textarea' && (
                <InputWrapper
                    data-automation="constructor-type-textarea"
                    textarea={ true }
                    expand={ scope.includes('additional') }
                    basic={ flow.includes('additional') }
                >
                    <NumberInput
                        placeholder={ placeholder }
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={ 3 }
                        onChange={ onInputChange }
                        value={ selected }
                    />
                </InputWrapper>
            )}
        </SurveyCardWrapper>
    );
};

export default SurveyConstructor;
