/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  getCalendlyHvUrl,
  getCalendlyUltraUrl,
  getCalendlyWatchesUrl,
  getDefaultCalendly,
} from 'src/config';
import { Profile } from 'src/services/api/backend.service.ts';
import {
  isFakePhone,
  isHighValueLeads,
  isUltraValueLeads,
  isWatchLeads,
} from 'src/helpers/common.ts';
import { Item } from 'src/types/item.ts';

export default class Calendly {
  static defaultCalendlyUrl: string = getDefaultCalendly();

  static loadCalendly(): void {
    if (document.querySelector('#calendlyScript') || document.querySelector('#calendlyStyle'))
      return;

    document.querySelector('html')!.style.height = 'initial';
    const head = document.querySelector('head')!;
    head.appendChild(
      this.createScriptElement(
        'https://assets.calendly.com/assets/external/widget.js',
        'calendlyScript',
      ),
    );
    head.appendChild(
      this.createStyleElement('https://calendly.com/assets/external/widget.css', 'calendlyStyle'),
    );
  }

  static removeCalendly(): void {
    this.removeElementById('#calendlyScript');
    this.removeElementById('#calendlyStyle');
  }

  static showPopupWidget(link?: string, params?: string): boolean {
    const calendlyLink = link || this.defaultCalendlyUrl;
    if (window.Calendly) {
      window.Calendly.showPopupWidget(`${calendlyLink}${params || ''}`);
    } else {
      console.log('Calendly is not loaded yet');
    }
    return false;
  }

  /**
   * @function getCalendlyLinkByValueCategory - get link by first main item in bundle | item object
   * @param item - item object main item in bundle
   * @returns string
   * **/
  static getCalendlyLinkByValueCategory(item: Item | undefined) {
    if (item) {
      if (isWatchLeads(item)) return getCalendlyWatchesUrl();
      // if (isJBLeads(item)) return getCalendlyJbUrl(); // todo check JB what to do
      if (isUltraValueLeads(item)) return getCalendlyUltraUrl();
      if (isHighValueLeads(item)) return getCalendlyHvUrl();
    }

    return this.defaultCalendlyUrl;
  }

  static isCalendlyEvent(event: MessageEvent): boolean {
    return event?.data?.event?.indexOf('calendly') === 0;
  }

  static calendlyParams(item: Item | undefined, user: Profile | Partial<Profile>): string {
    const { firstName, lastName, email, phoneNumber, userId } = user;
    const name = `${firstName} ${lastName || ''}`;
    const baseParams =
      `?name=${name}&email=${email}&utm_source=${userId}` +
      (item?.publicId ? `&utm_campaign=${item.publicId}` : '');

    const phoneParam = phoneNumber && !isFakePhone(phoneNumber) ? `&a1=1${phoneNumber}` : '';
    // todo pass just hv or pass all categories
    const utmTerm = isHighValueLeads(item) ? 'High Value' : '';

    return `${baseParams}${phoneParam}&utm_term=${utmTerm}`;
  }

  private static createScriptElement(src: string, id: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('id', id);
    return script;
  }

  private static createStyleElement(href: string, id: string): HTMLLinkElement {
    const style = document.createElement('link');
    style.setAttribute('rel', 'stylesheet');
    style.setAttribute('href', href);
    style.setAttribute('id', id);
    return style;
  }

  private static removeElementById(id: string): void {
    const element = document.querySelector(id);
    if (element) {
      element.parentElement?.removeChild(element);
    }
  }
}
