/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

export interface AddressSuggestion {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

export const initializeGoogleMapsServices = () => {
  if (window.google?.maps?.places?.AutocompleteService) {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService() as google.maps.places.AutocompleteService;
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    ) as google.maps.places.PlacesService;
    return { autocompleteService, placesService };
  } else {
    //TODO: add error handling
    console.error('Google Maps API failed to load');
    return { autocompleteService: null, placesService: null };
  }
};

export const fetchPlaceCoordinates = (
  placesService: google.maps.places.PlacesService,
  placeId: string,
): Promise<{ lat: number; lng: number }> => {
  return new Promise((resolve, reject) => {
    placesService.getDetails({ placeId }, (place, status) => {
      if (window.google && status === window.google.maps.places.PlacesServiceStatus.OK && place) {
        const geometry = place.geometry;

        if (geometry?.location) {
          const lat = geometry.location.lat();
          const lng = geometry.location.lng();

          resolve({ lat, lng });
        } else {
          reject('Latitude or longitude not available.');
        }
      } else {
        reject(`Error fetching place details: ${status}`);
      }
    });
  });
};

export const fetchAddressDetails = (
  lat: number,
  lng: number,
): Promise<{
  addressStreet: string;
  addressCity: string;
  addressState: string;
  addressZipcode: string;
}> => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      { location: { lat, lng } },
      (results: google.maps.GeocoderResult[] | undefined, status: google.maps.GeocoderStatus) => {
        if (status === window.google.maps.GeocoderStatus.OK && results && results.length > 0) {
          const addressComponents = results[0].address_components;

          const addressStreet = [
            addressComponents?.find((component) => component.types.includes('street_number'))
              ?.long_name ?? '',
            addressComponents?.find((component) => component.types.includes('route'))?.long_name ??
              '',
          ]
            .filter(Boolean)
            .join(' ');

          const addressCity =
            addressComponents?.find((component) => component.types.includes('locality'))
              ?.long_name ?? '';
          const addressState =
            addressComponents?.find((component) =>
              component.types.includes('administrative_area_level_1'),
            )?.short_name ?? '';

          const addressZipcode =
            addressComponents?.find((component) => component.types.includes('postal_code'))
              ?.long_name ?? '';

          resolve({ addressStreet, addressCity, addressState, addressZipcode });
        } else {
          reject('Failed to retrieve address components.');
        }
      },
    );
  });
};

export const getPlacePredictions = (
  autocompleteService: google.maps.places.AutocompleteService,
  input: string,
): Promise<AddressSuggestion[]> => {
  return new Promise((resolve) => {
    autocompleteService.getPlacePredictions(
      { input, types: ['address'] },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          const newSuggestions = predictions.map((prediction) => ({
            description: prediction.description,
            place_id: prediction.place_id,
            structured_formatting: {
              main_text: prediction.structured_formatting.main_text,
              secondary_text: prediction.structured_formatting.secondary_text,
            },
          }));
          resolve(newSuggestions);
        } else {
          resolve([]);
        }
      },
    );
  });
};
