/* eslint-disable @typescript-eslint/no-misused-promises */
import { Icon, Typography } from '@worthy-npm/worthy-common-ui-components';
import { Icon as CommonIcons } from '../Icons';
import PhotoUpload from '../photoUpload';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { PhotosCountSection, UploadImgWrap, UploadPhotoBtn } from './ItemPhoto.styles';
import { ItemTypes } from 'src/constants/item.constants';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
    getItemPhotos,
    isItemLoading,
    updateItemPhotos,
} from 'src/store/itemsSlice';
import { makeThumbnail } from 'src/helpers/common.ts';
import { Item } from 'src/types/item.ts';

interface ItemPhotoProps {
    itemPublicId: Item['publicId'];
    photosUrl: Item['photos'];
    itemType: Item['itemType'];
    disableUpload?: boolean;
}

const ItemPhoto = ({ itemPublicId, photosUrl, itemType, disableUpload }: ItemPhotoProps) => {
    const dispatch = useAppDispatch();
    const savedPhotos = useAppSelector((state) => getItemPhotos(state, itemPublicId));
    const savedPhotosLength = savedPhotos.length;
    const isLoading = useAppSelector((state) => isItemLoading(state, itemPublicId));
    const [ photoCount, setPhotoCount ] = useState<number>(0);
    const [ preview, setPreview ] = useState<string>('');
    const [ openPhotoUpload, setOpenPhotoUpload ] = useState(false);
    const itemTypeIcon = ItemTypes
        .filter((type) => type.type?.toLowerCase().includes(itemType))[0]?.icon || <Icon.CrossedRings />;

    useEffect(() => {
        if (!savedPhotosLength) {
            setPreview('');
            setPhotoCount(0);
            return;
        }
        const preview = savedPhotos[0].documentId < 0 ? savedPhotos[0]?.url : makeThumbnail(savedPhotos[0]?.url);
        setPreview(preview);
        setPhotoCount(savedPhotosLength);
    }, [ savedPhotosLength ]);

    const handlePhotoModal = (e: React.MouseEvent<HTMLDivElement> | null, open: boolean, photosLen?: number) => {
        if (openPhotoUpload !== open && savedPhotos.length > 0 && !isLoading) {
            console.log('modalUpload')

            e?.preventDefault();

            photosLen && setPhotoCount(photosLen);
            setOpenPhotoUpload(open);
            if (photosLen === 0) {
                setPreview('');
            }
        }

        if (isLoading) {
            e?.preventDefault();
            return;
        }
    };

    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {

        if (!event.target.files || (!disableUpload && savedPhotos.length) && isLoading) return;
        console.log('fileUpload')
        let selectedFiles = Array.from(event.target.files);

        if (selectedFiles.length > 6) {
            selectedFiles = selectedFiles.slice(0, 6);
        }
        // create preview for one file
        const toShow = URL.createObjectURL(selectedFiles[0]);
        setPreview(toShow);

        await uploadPhotos(selectedFiles);
        event.target.value = '';
    };

    const uploadPhotos = async (files: File[]) => {
        try {
            await dispatch(updateItemPhotos({ filesToAdd: files, filesToRemove: [], itemPublicId }));
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <UploadImgWrap
            data-automation="item-photo"
            id="item-photo"
            bgImage={ preview.length > 0 ? preview : undefined }
            onClick={ (e) => {
                handlePhotoModal(e, true)
            } }

            accept=".jpeg,.png,.jpg"
            status="empty"
            variant="filled"
            onChange={ handleFileUpload }
            isLoading={ isLoading }
            disableUpload={ disableUpload }
        >
            {
                !preview.length && itemTypeIcon
            }
            {
                !preview.length && !disableUpload && (
                    <UploadPhotoBtn
                        // accept=".jpeg,.png,.jpg"
                        // status="empty"
                        // onChange={ (event) => !disableUpload && handleFileUpload(event) }
                        // variant="filled"
                    >
                        <Typography
                            variant="caption"
                            fontWeight="500"
                            fontSize="12px"
                            noWrap
                        >
                          <CommonIcons.PlusIcon
                            sx={{
                              width: '8px',
                              height: '8px',
                            }}
                          />
                          {' Add photo'}
                        </Typography>
                    </UploadPhotoBtn>
                )
            }

            { openPhotoUpload && (
                <PhotoUpload
                    itemPublicId={ itemPublicId }
                    open={ openPhotoUpload }
                    onClose={ handlePhotoModal }
                    photos={ photosUrl || [] }
                />
            ) }
            {
                preview.length > 0 && (
                    <PhotosCountSection
                        data-automation="item-photo-count"
                        id="item-photo-count"
                    >
                        <Typography
                            variant="caption"
                            fontWeight="500"
                            fontSize="10px"
                        >
                            { photoCount }
                        </Typography>
                        <CommonIcons.MoreSquaresPhoto />
                    </PhotosCountSection>

                )
            }

        </UploadImgWrap>
    );
};
export default ItemPhoto;
