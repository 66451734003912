import React, { useEffect, useState } from 'react';
import { Box, RadioGroup, Typography } from '@worthy-npm/worthy-common-ui-components';
import {
    FloatingDivider,
    InsuranceText,
    ItemCountInShipment,
    ItemsCount,
    ItemsCountWrapper,
    Logos,
    MethodsCtaBtn,
    MethodWrapper,
    PartnersWrapper,
    SchedulingMethodsContainer,
    Title,
} from './SchedulingMethods.styles.tsx';
import { Icon } from 'src/components/Icons.tsx';
import { FormControl, RadioBtn } from 'src/styles/RadioBtn/RadioBtn.styles.tsx';
import {
    getMainItemPublicId,
    getSchedulingItems,
    getSelectedShippingMethod,
    nextStep,
    setShippingMethod,
} from 'src/store/schedulingSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import ClickableHelpTooltip from 'src/components/ClickableHelpTooltip/ClickableHelpTooltip.tsx';
import { SchedulingType } from 'src/constants/item.constants.tsx';
import { sendUserEvent } from 'src/helpers/common.ts';
import { Item } from 'src/types/item.ts';
import SchedulingHeader from 'src/components/SchedulingPopUp/schedulingHeader.tsx';
import Calendly from 'src/helpers/calendly.ts';
import { getUser } from 'src/store/userSlice';
import { getIsAllPending } from 'src/store/itemsSlice';

const Methods = [
    {
        value: SchedulingType.PICKUP,
        label: 'Schedule FedEx Pickup',
        btnTitle: 'Continue with Fedex Pickup',
        tooltipTxt: 'Select this option if you prefer FedEx to pick up your shipment directly from your specified location.',
    },
    {
        value: SchedulingType.DROPOFF,
        label: 'Drop Off at FedEx',
        btnTitle: 'Continue with Fedex Drop-off',
        tooltipTxt: 'Choose this option to drop off your shipment at a designated FedEx location.',
    },
];

const SchedulingMethods: React.FC<{isPC: boolean}> = ({isPC}) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);
    const mainItemPublicId = useAppSelector(getMainItemPublicId);
    const allPending = useAppSelector(getIsAllPending);
    const method: SchedulingType = useAppSelector(getSelectedShippingMethod);
    const items: Item[] = useAppSelector(getSchedulingItems);

    const itemsCount = items.length;
    const [ selectedMethod, setSelectedMethod ] = useState<SchedulingType>(method);
    const [ btnTitle, setBtnTitle ] = useState(Methods.find(i => i.value === method)!.btnTitle);

    useEffect(() => {
        Calendly.loadCalendly();
        window.addEventListener('message', handleCalendly);

        return () => {
            Calendly.removeCalendly();
        };
    }, []);

    const handleCalendly = (event: MessageEvent) => {
        if (Calendly.isCalendlyEvent(event)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (event.data.event.indexOf('event_scheduled') > -1) {
                console.info('Calendly event_scheduled', event.data);
            }
        }
    };

    const scheduleCall = () => {
        Calendly.showPopupWidget(undefined, Calendly.calendlyParams(undefined, user));
    }

    const onNextClick = () => {
        dispatch(setShippingMethod(selectedMethod));
        dispatch(nextStep());
    };

    const getRadioBtn = (value: string, label: string, tooltipTxt: string) => (
        <MethodWrapper key={ value }>
            <FormControl
                key={ `form-control-${ value }` }
                data-automation={ `radio-${ value }` }
                value={ value }
                control={ <RadioBtn /> }
                label={ label }
                disabled={ !mainItemPublicId }
            />
            <ClickableHelpTooltip
                key={ `tooltip-${ value }` }
                title={ tooltipTxt }
                large={ true }
            />
        </MethodWrapper>
    );

    const getPartnersLogos = () => (
        <Logos>
            <Icon.FedexIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '65px',
                    height: '20px',
                } }
            />
            <Icon.LloydsIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '63px',
                    height: '13px',
                } }
            />
            <Icon.GiaIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '65px',
                    height: '29px',
                } }
            />
        </Logos>
    );

    const getItemsCount = () => (
        <ItemsCountWrapper>
            <ItemsCount>
                { itemsCount } { itemsCount === 1 ? 'item' : 'items' }
            </ItemsCount>
        </ItemsCountWrapper>
    );

    const handleMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as SchedulingType;
        sendUserEvent(`${ value } radio button selected`, mainItemPublicId);
        setSelectedMethod(value);
        setBtnTitle(Methods.find((method) => method.value === value)!.btnTitle);
    };

    const getSchedulingMethods = () => (
        <RadioGroup
            aria-labelledby="scheduling-popup-radio-buttons-group-label"
            value={ selectedMethod }
            name="scheduling-popup-radio-buttons-group"
            sx={ {
                alignSelf: 'stretch',
            } }
            onChange={ (e) => handleMethodChange(e) }
        >
            { Methods.map((method) => getRadioBtn(method.value, method.label, method.tooltipTxt)) }
        </RadioGroup>
    );

    return (
        <>
            <SchedulingHeader isPC={isPC} title="Scheduling options" firstStep={ true } />
            <Title align="left">How would you like to send your item?</Title>

            <SchedulingMethodsContainer empty={allPending}>
                { getSchedulingMethods() }

                { !allPending ? (
                    <>
                        <PartnersWrapper>
                            <FloatingDivider flexItem />
                            <InsuranceText>**Insurance and fees covered</InsuranceText>
                            { getPartnersLogos() }
                        </PartnersWrapper>
                        <ItemCountInShipment>
                            <InsuranceText>Items in this shipment</InsuranceText>
                            { getItemsCount() }
                        </ItemCountInShipment>
                    </>
                ) : (
                    <Box>
                        <Typography variant="caption">
                            Your item is still pending approval; therefore, shipping options are not yet available. For more information on item
                            status or next steps, schedule a call with our team.
                        </Typography>
                    </Box>
                ) }

                <MethodsCtaBtn
                    variant="contained"
                    onClick={ onNextClick }
                    disabled={ !mainItemPublicId }
                    data-automation="shipping-method-btn"
                >
                    { btnTitle }
                </MethodsCtaBtn>
                {
                    allPending && (
                        <MethodsCtaBtn
                            variant="contained"
                            onClick={ scheduleCall }
                            data-automation="shipping-method-btn"
                        >
                            Schedule Call
                        </MethodsCtaBtn>
                    )
                }
            </SchedulingMethodsContainer>
        </>
    );
};

export default SchedulingMethods;
