import { backendService } from 'src/services/api/backend.service.ts';
import { createAppSlice } from 'src/store/createAppSlice.ts';
import { PayloadAction } from '@reduxjs/toolkit';
import { Item } from 'src/types/item.ts';
import { worthyService } from 'src/services/api/worthy.service.ts';
import _ from 'lodash';
import { SchedulingType, WorkflowStatus } from 'src/constants/item.constants.tsx';

interface ItemsState {
    items: Item[] | [];
    loading: boolean;
    error: string | null;
    loadingItems: string[];
    allPending: boolean;
}

interface TempItem {
    publicIds: string[];
    shipType: SchedulingType;
    location: string;
}

const initialState: ItemsState = {
    items: [],
    loading: false,
    error: null,
    loadingItems: [],
    allPending: false,
};

export const itemsSlice = createAppSlice({
    name: 'items',
    initialState,
    reducers: (create) => ({
        updateItem: create.reducer((state, action: PayloadAction<Item>) => {
            let newItem = true;

            state.items = state.items.filter((item) => !item.publicId.includes('temp'));

            state.items = state.items.map((item) => {
                if (item && item.publicId === action.payload.publicId) {
                    newItem = false;

                    return _.mergeWith({}, item, action.payload, (objValue, srcValue, key) => {

                        if (key === 'status' && item.status === WorkflowStatus.SHIPPING_SCHEDULED && action.payload.status === WorkflowStatus.SCHEDULING) {
                            return item.status;
                        }

                        if (Array.isArray(objValue)) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                            return objValue.map((innerItem, index) =>
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
                                _.merge({}, innerItem, srcValue[index] || {}),
                            );
                        }
                    });

                } else {
                    return item;
                }
            });

            if (newItem) {
                state.items.push(action.payload);
            }
        }),

        fetchItems: create.asyncThunk<Item[] | []>(
            async () => {
                return backendService.fetchUserItems();
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                fulfilled: (state, action: PayloadAction<Item[] | []>) => {
                    state.items = action.payload;
                    state.loading = false;
                },
                rejected: (state) => {
                    state.loading = false;
                },
            },
        ),
        updateItemPhotos: create.asyncThunk<
            string[],
            { filesToAdd: File[], filesToRemove: string[], itemPublicId: string }
        >(
            async ({ filesToAdd, filesToRemove, itemPublicId }) => {
                if (filesToRemove.length > 0) {
                    await worthyService.removeImages(filesToRemove, itemPublicId);
                }

                if (filesToAdd.length > 0) {
                    const { paths } = await worthyService.uploadImages(filesToAdd, itemPublicId);
                    return paths;
                }
                return [];
            },
            {
                pending: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.photos = item.photos
                                          .filter((photo) => !action.meta.arg.filesToRemove.includes(photo.url))
                                          .concat(action.meta.arg.filesToAdd.map((file, idx) => ({
                                              url: URL.createObjectURL(file),
                                              documentId: -(idx + 1),
                                          })));

                        if (item.photos.length === 0 && action.meta.arg.filesToAdd.length === 0) {
                            item.photos = [];
                        }
                    }

                    state.loadingItems.push(action.meta.arg.itemPublicId);
                },
                fulfilled: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.photos = item.photos.filter(photo => photo.documentId > 0);
                        if (action.payload.length > 0) {
                            item.photos = [
                                ...item.photos,
                                ...action.payload.map((url, idx) => ({
                                    url,
                                    documentId: idx + 1,
                                }))
                            ];
                        }

                        if (item.photos.length === 0 && action.meta.arg.filesToRemove.length > 0 && action.meta.arg.filesToAdd.length === 0) {
                            item.photos = [];
                        }
                    }

                    state.loadingItems = state.loadingItems.filter(id => id !== action.meta.arg.itemPublicId);
                },
                rejected: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.photos = [
                            ...item.photos,
                            ...action.meta.arg.filesToRemove.map((url, idx) => ({
                                url,
                                documentId: idx + 1,
                            }))
                        ];

                        item.photos = item.photos.filter(photo => photo.documentId > 0);
                    }

                    state.loadingItems = state.loadingItems.filter(id => id !== action.meta.arg.itemPublicId);
                },
            }
        ),
      updateCertificate: create.asyncThunk<
        void,
        { itemPublicId: string, certificate: string }
      >(
        async ({ itemPublicId, certificate }) => {
          await worthyService.updateCertificate(itemPublicId, certificate);
        },
        {
          pending: (state, action) => {
            const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

            if (item) {
              item.itemDetails.certificateId = action.meta.arg.certificate;
            }

            state.loadingItems.push(action.meta.arg.itemPublicId);
          },
          fulfilled: (state, action) => {
            const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

            if (item) {
              item.itemDetails.certificateId = action.meta.arg.certificate;
            }

            state.loadingItems = state.loadingItems.filter(id => id !== action.meta.arg.itemPublicId);
          },
          rejected: (state, action) => {
            const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

            if (item) {
              item.itemDetails.certificateId = '';
            }

            state.loadingItems = state.loadingItems.filter(id => id !== action.meta.arg.itemPublicId);
            },
        }
      ),
        createTempValidItem: create.reducer((state, action: PayloadAction<{ itemType: string }>) => {
            const tempItem: Item = {
                bundleId: 0,
                createdAt: '',
                itemDescription: '',
                itemDetails: {
                    certificateId: '',
                    certificateType: '',
                },
                photos: [],
                appointmentDetails: null,
                schedulingDetails: {
                    type: SchedulingType.PICKUP,
                    localStartTimeString: '',
                    localEndTimeString: '',
                    address: ''
                },
                userId: 0,
                valueCategory: '',
                publicId: `temp-${ Date.now() }`,
                itemType: action.payload.itemType,
                status: WorkflowStatus.SCHEDULING
            };

            state.items = [...state.items, tempItem];
        }),
        removeTempValidItem: create.reducer((state) => {
            state.items = state.items.filter((item) => !item.publicId.includes('temp'));
        }),
        createTempShippingItem: create.reducer((state, action: PayloadAction<TempItem>) => {
            const items = _.filter(state.items, (item) => action.payload.publicIds.includes(item.publicId));
            _.forEach(items, (item) => {
                if (!item.schedulingDetails?.shipment) {
                    item.status = WorkflowStatus.SHIPPING_SCHEDULED;
                    // @ts-ignore
                    item.schedulingDetails = {
                        type: action.payload.shipType || SchedulingType.DROPOFF,
                        address: action.payload.location,
                    };
                }
            });
        }),
        setIsAllPending: create.reducer((state, action: PayloadAction<boolean>) => {
            state.allPending = action.payload;
        }),
    }),

    selectors: {
        getIsAllPending: (state) => state.allPending,
        selectLoading: (state) => state.loading,
        isItemLoading: (state, publicId: string) => state.loadingItems.includes(publicId),
        selectError: (state) => state.error,
        getItems: (state) => state.items,
        getItemPhotos: (state, publicId: string) => {
            if (!publicId) {
                return [];
            }
            const item = state.items.find((item) => item.publicId === publicId);
            return item ? item.photos : [];
        },
        getItemAppointment: (state, publicId?: string) => {
            if (!publicId) {
                return null;
            }
            const item = state.items.find((item) => item.publicId === publicId);
            return item ? item.appointmentDetails : null;
        },
    },
});

export const {
    selectLoading,
    selectError,
    getItems,
    getItemPhotos,
    getItemAppointment,
    isItemLoading,
    getIsAllPending,
} = itemsSlice.selectors;
export const {
    setIsAllPending,
    fetchItems,
    updateItem,
    updateItemPhotos,
    updateCertificate,
    createTempValidItem,
    removeTempValidItem,
    createTempShippingItem,
} = itemsSlice.actions;
