import styled from '@emotion/styled';
import { Stepper } from '@worthy-npm/worthy-common-ui-components';

export const StyledStepper = styled(Stepper, {
  shouldForwardProp: (prop) => !['color', 'currentStepIndex'].includes(prop),
})<{ color: string; currentStepIndex: number }>(({ theme, color, currentStepIndex }) => ({
  overflow: 'auto',
  '& .MuiStepLabel-label': {
    fontWeight: 300,
  },
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    marginTop: 0,
  },
  '& .MuiStepConnector-line': {
    borderTopWidth: theme.spacing(0.5),
    borderColor: theme.palette.divider,
  },
  '& .Mui-completed .MuiStepConnector-line': {
    borderColor: color,
  },
  '& .Mui-active .MuiStepConnector-line': {
    borderColor: color,
  },
  [`& .MuiStep-root:nth-of-type(${currentStepIndex + 1}) .MuiStepLabel-label.MuiStepLabel-alternativeLabel`]:
    {
      color: color,
    },
  '& .MuiStepLabel-iconContainer': {
    backgroundColor: 'transparent',
    zIndex: 1,
  },
  '& .MuiStepConnector-root': {
    right: 0,
    left: 0,
    top: theme.spacing(0.7),
    width: '100%',
  },
  '& .MuiStepLabel-active ~ .MuiStepLabel-root .MuiStepLabel-label': {
    color: color,
  },
  '& .MuiStepLabel-labelContainer': {
    color: theme.palette.grey[600],
  },
}));
