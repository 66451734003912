import React from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';

interface AbortedStepProps {
  item: Item;
}

const AbortedStep: React.FC<AbortedStepProps> = () => {
  const onclick = () => {
    console.log('Clicked');
  };
  return (
    <StyledStack>
      <DescriptionText>Unfortunately the reserve price was not met.</DescriptionText>
      <CtaButton onClick={onclick}>Initiate Return</CtaButton>
    </StyledStack>
  );
};

export default AbortedStep;
