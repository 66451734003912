import React from 'react';
import dayjs from 'dayjs';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import { CircularProgress } from '@worthy-npm/worthy-common-ui-components';
import { onPrintShippingLabelClick } from './StepsAction.helpers';

export const printLabel = async (item: Item) => {
  await onPrintShippingLabelClick(item).catch(err => console.log(err));
};

interface PickupStepProps {
  item: Item;
  onActionShow?: () => void;
}


const PickupStep: React.FC<PickupStepProps> = ({ item, onActionShow }) => {
  const [isClicked, setIsClicked] = React.useState(false);
  const onclick = () => {
    onActionShow?.();
    setIsClicked(true);
  };
  const isLabelReady = item?.schedulingDetails?.shipment?.shippingLabelUrl;
  const startDate = item?.schedulingDetails?.localStartTimeString;
  const endDate = item?.schedulingDetails?.localEndTimeString;
  const startDateString = startDate ? dayjs(startDate.split('+')[0]).format('MMMM. D, YYYY, H') : "";
  const endDateString = endDate ? dayjs(endDate.split('+')[0]).format(' - Ha') + ' ET': '';
  return (
    <StyledStack gap={1.5}>
      <DescriptionText>
        {startDateString + endDateString}
        <br />{item?.schedulingDetails?.address}
      </DescriptionText>
        {isClicked ? (<CtaButton
                onClick={ () => {
                    printLabel(item).catch(err => console.error(err));
                } }
                disabled={ !isLabelReady }
            >
                { 'Print Shipping Labels' } {!isLabelReady && <CircularProgress size={20} color="inherit" sx={{ml: 1}} />}
            </CtaButton>) :
            <CtaButton onClick={onclick}>Prepare for pickup</CtaButton>
        } 
    </StyledStack>
  );
};

export default PickupStep;
