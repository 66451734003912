import React from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import { getAppUrl } from 'src/config';

interface GetOfferStepProps {
  item: Item;
}

const GetOfferStep: React.FC<GetOfferStepProps> = ({item}) => {
  
  const onClick = () => {
    window.open(`${getAppUrl()}/item/${item.publicId}`);;
  }

  return (
    <StyledStack>
      <DescriptionText>
        We’d like to purchase your item directly, simplifying the selling process for you.
      </DescriptionText>
      <CtaButton onClick={onClick}>See offer</CtaButton>
    </StyledStack>
  );
};

export default GetOfferStep;
