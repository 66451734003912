import GTM from 'react-gtm-module';
import Ga from './ga';
import { getGtmAuth, getGtmEnvId, getRuntimeGtmId, getRuntimeType, isProd } from 'src/config';

const tagManagerArgs = {
    gtmId: getRuntimeGtmId(),
    auth: getGtmAuth(),
    preview: getGtmEnvId(),
};

if (getRuntimeType() !== 'local') {
    GTM.initialize(tagManagerArgs);
}

if (isProd() && !getRuntimeGtmId()) {
    window.rollbar?.error('GTM ID is missing');
}

class GaService extends Ga {
    // Add custom events here like navigation events, etc.
    // example of delayed input event, to avoid triggering too many events

    private static inputEventDelay = 500;
    private static inputDelays: Record<string, number> = {};

    public static GA4InputChange(
        inputType: string,
        inputName: string,
        data: Omit<GA.InteractionEvent, 'action' | 'eventName'>,
    ) {
        if (this.inputDelays[inputName]) {
            window.clearTimeout(this.inputDelays[inputName]);
        }

        const delayedEvent = () => {
            if (inputType === 'checkbox' || inputType === 'toggle') {
                return window.setTimeout(() => {
                    this.SendActionToggle(data);
                }, this.inputEventDelay);
            }

            if (inputType === 'range' || inputType === 'select') {
                return window.setTimeout(() => {
                    this.SendActionSelect(data);
                }, this.inputEventDelay);
            }

            return window.setTimeout(() => {
                this.SendActionInput(data);
            }, this.inputEventDelay);
        };

        this.inputDelays[inputName] = delayedEvent();
    }
}

export default GaService;
