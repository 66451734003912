import { backendService } from 'src/services/api/backend.service.ts';
import { createAppSlice } from 'src/store/createAppSlice.ts';
import { PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'src/types/notification.ts';

interface NotificationsState {
    notifications: Notification[] | [];
    loading: boolean;
    error: string | null;
}

const initialState: NotificationsState = {
    notifications: [],
    loading: false,
    error: null,
};

export const notificationsSlice = createAppSlice({
    name: 'notifications',
    initialState,
    reducers: (create) => ({
        updateNotification: create.reducer((state, action: PayloadAction<Notification>) => {
            let newNotification = true;

            state.notifications = state.notifications.map((notification) => {
                if (notification && notification.id === action.payload.id) {
                    newNotification = false;
                    return { ...notification, ...action.payload };
                } else {
                    return notification;
                }
            });

            if (newNotification) {
                state.notifications.push(action.payload);
            }
        }),

        fetchNotifications: create.asyncThunk<Notification[] | []>(
            async () => {
                return backendService.fetchNotifications();
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                fulfilled: (state, action: PayloadAction<Notification[] | []>) => {
                    state.notifications = action.payload;
                    state.loading = false;
                },
                rejected: (state) => {
                    state.loading = false;
                },
            },
        ),
    }),

    selectors: {
        selectLoading: (state) => state.loading,
        selectError: (state) => state.error,
        getNotifications: (state) => state.notifications,
    },
});

export const { selectLoading, selectError, getNotifications } = notificationsSlice.selectors;
export const { fetchNotifications, updateNotification } = notificationsSlice.actions;
