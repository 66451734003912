export const gaNullEventFields = {
  eventName: null,
  location1: null,
  location2: null,
  location3: null,
  location4: null,
  location5: null,
  action: null,
  actionedObject: null,
  actionedValue: null,
  actionedObject2: null,
  actionedValue2: null,
  actionedObject3: null,
  actionedValue3: null,
  actionedObject4: null,
  actionedValue4: null,
  actionedObject5: null,
  actionedValue5: null,
  fieldName: null,
  fieldValue: null,
  topic: null,
};

export enum GaEvents {
  Interaction = 'interaction',
  Feedback = 'feedback',
}

export enum GaActions {
  // feedback actions
  Open = 'open',
  Success = 'success',
  Failure = 'failure',
  Close = 'close',
  // interaction actions
  Select = 'select',
  Clear = 'clear',
  Focus = 'focus',
  Input = 'input',
  Submit = 'submit',
  Toggle = 'toggle',
  Navigate = 'navigate',
  Sort = 'sort',

  // we should try to avoid using this action
  Click = 'click',
}
