import { useEffect } from 'react';
import MyItemsList from 'src/views/MyItemsList';
import BaseLayout from 'src/layout/baseLayout';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchItems, getItems } from 'src/store/itemsSlice';
import FAQ from 'src/components/infoSections/faq';
import MoreToSellSection from 'src/views/MoreToSellSection';
import { Stack } from '@worthy-npm/worthy-common-ui-components';
import { MyItemQuestions } from 'src/data/MyItemFaqs';

const MyItems = () => {
    const dispatch = useAppDispatch();
    const itemList = useAppSelector(getItems);

    useEffect(() => {
        document.title = 'My Items';

        !itemList.length && dispatch(fetchItems());
    }, [dispatch, itemList.length]);

    return (
        <BaseLayout>
            <MyItemsList items={itemList} />
            {
                itemList.length !== 0 && (
                    <Stack
                        m={2}
                        alignItems={'center'}
                    >
                        <MoreToSellSection />
                    </Stack>
                )
            }
            <FAQ questions={MyItemQuestions} />
        </BaseLayout>
    );
};

export default MyItems;
