import { Socket } from 'socket.io-client';
import { MiddlewareAPI, Dispatch, Action } from 'redux';
import { updateItem } from '../store/itemsSlice';
import { setUserProfile } from '../store/userSlice';
import { Profile } from 'src/services/api/backend.service.ts';
import { Item } from 'src/types/item.ts';
import { Notification } from 'src/types/notification.ts';
import { updateNotification } from "src/store/notificationsSlice";

const subscribedEvents = new Set<string>();

export default function socketMiddleware(socket: Socket) {
    return (store: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
        const { dispatch } = store;
        const { type } = action;

        if (type === 'items/fetchItems/fulfilled' && !subscribedEvents.has('ITEM_UPDATED')) {
            console.log('Subscribe to ITEM_UPDATED event', type);

            socket.on('ITEM_UPDATED', (payload: Item) => {
                console.log('Received ITEM_UPDATED', payload);
                dispatch(updateItem(payload));
            });

            subscribedEvents.add('ITEM_UPDATED');
        }

        if (
            type === 'user/fetchUserProfile/fulfilled' &&
            !subscribedEvents.has('PROFILE_UPDATED')
        ) {
            console.log('Subscribe to PROFILE_UPDATED event', type);

            socket.on('PROFILE_UPDATED', (payload: Partial<Profile>) => {
                console.log('Received PROFILE_UPDATED', payload);
                dispatch(setUserProfile(payload));
            });

            subscribedEvents.add('PROFILE_UPDATED');
        }

      if (type === 'notifications/fetchNotifications/fulfilled' && !subscribedEvents.has('NOTIFICATION_UPDATED')) {
        console.log('Subscribe to NOTIFICATION_UPDATED event', type);

        socket.on('NOTIFICATION_UPDATED', (payload: Notification) => {
          console.log('Received NOTIFICATION_UPDATED', payload);
          dispatch(updateNotification(payload));
        });

        subscribedEvents.add('NOTIFICATION_UPDATED');
      }

        return next(action);
    };
}
