/* eslint-disable @typescript-eslint/no-misused-promises */

import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    Checkbox,
    CircularProgress,
    List,
    ListItemText, Popover, Typography,
} from '@worthy-npm/worthy-common-ui-components';
import { Icon } from 'src/components/Icons.tsx';
import { BottomFixedCtaBtn } from 'src/styles/SchedulingCTA/SchedulingCTA.styles.tsx';
import {
    FormContainer,
    FormWrapper,
    RadioWrapper,
    TextInput,
    FormControl,
    Locations,
    Location,
    Street,
    State,
} from 'src/components/AddressInput/AddressInput.styles.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
    getApt,
    getPhoneNumber,
    getStreet,
    nextStep,
    setSchedulingPhoneNumber,
    setSchedulingApt,
    setSchedulingStreet,
    getPrivateResidence,
    getCity,
    getState,
    getZipcode,
    setFormattedAddress,
    setSchedulingPrivateResidence,
    getMainItemPublicId,
    getFormattedAddress,
    getSelectedShippingMethod,
    setDropOffLocation,
    createShipping,
    setLoading,
    getError,
    setError,
    setShippingMethod, setFlowToStart, getLocations, getSelectedItems,
} from 'src/store/schedulingSlice';
import { getUser, isUserMissingPhoneNumber } from 'src/store/userSlice';
import { debounce } from 'lodash';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { GenerateShippingParams, worthyService } from 'src/services/api/worthy.service.ts';
import { prepareDropOffLocations } from 'src/helpers/scheduling/common.ts';
import {
    AddressSuggestion,
    fetchAddressDetails,
    fetchPlaceCoordinates,
    getPlacePredictions,
    initializeGoogleMapsServices,
} from 'src/components/AddressInput/googleMapsHelper.tsx';
import { normalizePhoneNumber, sendUserEvent } from 'src/helpers/common.ts';
import Ga from 'src/services/ga.service/ga.ts';
import { SchedulingType } from 'src/constants/item.constants.tsx';
import { LocationType } from 'src/types/shipping.ts';
import SchedulingHeader from 'src/components/SchedulingPopUp/schedulingHeader.tsx';
import { NoSlotsButton, NoSlotsContainer, NoSlotsWrapper } from 'src/components/PickupSlots/PickupSlots.styles.tsx';

const LabelStyle = {
    color: '#7A8994',
    paddingLeft: '16px',
};

const AddressInput = ({ isPC }: { isPC: boolean }) => {
    const dispatch = useAppDispatch();

    const shipmentMethod = useAppSelector(getSelectedShippingMethod);
    const userMissingPhoneNumber = useAppSelector(isUserMissingPhoneNumber);
    const user = useAppSelector(getUser);
    const mainItemPublicId = useAppSelector(getMainItemPublicId);
    const selectedScheduled = useAppSelector(getSelectedItems);
    const isError = useAppSelector(getError);
    const shipmentLocations = useAppSelector(getLocations);
    const formattedAddress = useAppSelector(getFormattedAddress);


    const [ autocompleteService, setAutocompleteService ] =
        useState<google.maps.places.AutocompleteService | null>(null);
    const [ placesService, setPlacesService ] = useState<google.maps.places.PlacesService | null>(
        null,
    );
    const [ suggestions, setSuggestions ] = useState<AddressSuggestion[]>([]);
    const [ address, setAddress ] = useState(formattedAddress);
    const [ street, setStreet ] = useState(useAppSelector(getStreet));
    const [ city, setCity ] = useState(useAppSelector(getCity));
    const [ state, setState ] = useState(useAppSelector(getState));
    const [ zipcode, setZipcode ] = useState(useAppSelector(getZipcode));
    const [ apt, setApt ] = useState(useAppSelector(getApt));
    const [ privateResidence, setPrivateResidence ] = useState(useAppSelector(getPrivateResidence));
    const [ phoneNumber, setPhoneNumber ] = useState(useAppSelector(getPhoneNumber));
    const [ phoneError, setPhoneError ] = useState('');
    const [ streetError, setStreetError ] = useState('');
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ loadingSuggestions, setLoadingSuggestions ] = useState<boolean>(false);
    const [ open, setOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState<HTMLInputElement | null>(null);

    useEffect(() => {
        const { autocompleteService, placesService } = initializeGoogleMapsServices();
        setAutocompleteService(autocompleteService);
        setPlacesService(placesService);
    }, []);

    const validateForm = useCallback(() => {
        let valid = true;

        if (!street || (!privateResidence && !apt)) {
            setStreetError('Please enter a valid street address and apartment number');
            valid = false;
        } else {
            setStreetError('');
        }

        if (userMissingPhoneNumber && (!phoneNumber || !isValidPhoneNumber(phoneNumber, 'US'))) {
            setPhoneError('Please enter a valid phone number');
            valid = false;
        } else {
            setPhoneError('');
        }

        return valid;
    }, [ street, privateResidence, apt, userMissingPhoneNumber, phoneNumber ]);

    const fetchSuggestions = useCallback(
        debounce((value: string) => {
            if (value && autocompleteService) {
                setLoadingSuggestions(true);
                getPlacePredictions(autocompleteService, value)
                    .then((suggestions) => {
                        setSuggestions(suggestions);
                        setOpen(true);
                    })
                    .catch(() => setSuggestions([]))
                    .finally(() => setLoadingSuggestions(false));
            } else {
                setSuggestions([]);
                setLoadingSuggestions(false);
            }
        }, 500),
        [ autocompleteService ],
    );

    const handleStreetChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setAddress(value);
            fetchSuggestions(value);
            setAnchorEl(e.currentTarget);
        },
        [ fetchSuggestions ],
    );

    const resetErrors = () => {
        dispatch(setError(''));
        dispatch(setLoading(false));
    };

    const setToDropOff = () => {
        dispatch(setShippingMethod(SchedulingType.DROPOFF));
        dispatch(setFlowToStart());
        resetErrors();
    };

    const handleAptChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!isNaN(Number(value))) setApt(value);
    }, []);


    const closePopup = useCallback(() => {
        setOpen(false);
        setAnchorEl(null);
    }, []);

    const handleSuggestionClick = (suggestion: AddressSuggestion) => {
        setAddress(suggestion.description);

        closePopup();
        setSuggestions([]);

        if (placesService) {
            fetchPlaceCoordinates(placesService, suggestion.place_id)
                .then(({ lat, lng }) => {
                    return fetchAddressDetails(lat, lng);
                })
                .then(({ addressStreet, addressCity, addressState, addressZipcode }) => {
                    setStreet(addressStreet);
                    setCity(addressCity);
                    setState(addressState);
                    setZipcode(addressZipcode);
                })
                .catch((error) => {
                    console.error('Error fetching place details:', error);
                });
        }
    };

    const generateShipping = async () => {
        let locations: LocationType[] = [];
        const addressObj = {
            street: street,
            street2: apt,
            city: city,
            state: state,
            zipcode: zipcode,
        };
        const userObj = {
            first_name: user.firstName ?? '',
            last_name: user.lastName ?? '',
        };
        const generateShippingParams: GenerateShippingParams = {
            itemPublicIds: selectedScheduled,
            addressObj,
            user: userObj,
        };

        if (shipmentLocations.length > 0 && address === formattedAddress) {
            return shipmentLocations;
        }

        try {
            const { item } = await dispatch(createShipping(generateShippingParams)).unwrap();
            const shipment = item.shipment;

            dispatch(setFormattedAddress(address || shipment?.formatted_address));
            locations = shipment.locations;
        } catch (err) {
            sendUserEvent('Pickup/Drop-off modal presented', mainItemPublicId);
            dispatch(setError('Error generating shipping'));
            dispatch(setFormattedAddress(address));
            dispatch(setLoading(false));
            //TODO: present error to user
            console.error(err);
        }

        return locations;
    };

    const updatePhoneNumber = async () => {
        dispatch(setSchedulingPhoneNumber(phoneNumber));
        sendUserEvent('Complete Phone number modal - clicked next', mainItemPublicId);
        await worthyService
            .updateUserPhoneNumber(phoneNumber, user.userId!)
            .then((status) => {
                console.log('phone updated status: ', status);
            })
            .catch((err) => {
                //TODO: present error to user
                console.error(err);
            });
    };

    const handleDropOffScheduled = async (shipmentLocations: LocationType[]) => {
        try {
            //TODO:
            Ga.SendActionNavigate({
                event: 'interaction',
                interactionType: 'success',
                location1: 'app',
                location2: 'lightListing',
                location3: 'setUpShipmentPopup',
                actionedObject: 'deliveryScheduled',
                actionedValue: 'fedexDropOff',
                appVersion: '123',
                buyerId: undefined,
            });
            sendUserEvent('Finish drop-off button clicked', mainItemPublicId);

            const locations = await prepareDropOffLocations(shipmentLocations);
            await worthyService.scheduleDropOff({
                itemPublicId: mainItemPublicId,
                dropOffLocations: locations,
            });
            dispatch(setDropOffLocation(locations[0].address));
        } catch (error) {
            console.error('Error handleDropOffScheduled:', error);
            dispatch(setError('Error scheduling drop off'));
            throw error;
        }
    };

    const onNextClick = useCallback(async () => {
        if (!validateForm()) return;

        try {
            setLoadingForm(true);

            dispatch(setSchedulingStreet(street));
            dispatch(setSchedulingApt(apt));
            dispatch(setSchedulingPrivateResidence(privateResidence));

            if (phoneNumber) await updatePhoneNumber();
            const shipmentLocations = await generateShipping();

            sendUserEvent('Next button clicked - address provided', mainItemPublicId);
            //TODO:
            Ga.SendActionNavigate({
                event: 'interaction',
                interactionType: 'success',
                location1: 'app',
                location2: 'lightListing',
                location3: 'setUpShipmentPopup',
                actionedObject: 'addressAdded',
                appVersion: '123',
                buyerId: undefined,
            });

            if (shipmentMethod === SchedulingType.DROPOFF) {
                await handleDropOffScheduled(shipmentLocations);
            }

            dispatch(nextStep());
        } catch (error) {
            setLoadingForm(false);
            //TODO: add error handling
            console.error(error);
        } finally {
            dispatch(setLoading(false));
            setLoadingForm(false);
        }
    }, [
        validateForm,
        street,
        apt,
        privateResidence,
        phoneNumber,
        updatePhoneNumber,
        generateShipping,
        dispatch,
        handleDropOffScheduled,
    ]);

    const handlePrivateResidenceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPrivateResidence(e.target.checked);
        setApt('');
    };

    const getPhoneField = () => {
        if (userMissingPhoneNumber) {
            return (
                <TextInput
                    key="phone-number"
                    id="phone-number"
                    fullWidth
                    label="Phone Number"
                    autoFocus
                    error={ !!phoneError }
                    helperText={ phoneError }
                    InputLabelProps={ { style: LabelStyle } }
                    data-automation="phone-input"
                    variant="standard"
                    value={ phoneNumber }
                    placeholder="(423) 456-7890"
                    onChange={ (e) => {
                        const formattedValue = normalizePhoneNumber(e.target.value);
                        setPhoneNumber(formattedValue);
                    } }
                />
            );
        }

        return null;
    };

    const getAutoCompleteStreetField = () => (
        <>
            <TextInput
                id="address-street"
                fullWidth
                label="Street Address"
                error={ !!streetError }
                helperText={ streetError }
                InputLabelProps={ { style: LabelStyle } }
                data-automation="street-input"
                variant="standard"
                value={ address }
                onChange={ handleStreetChange }
                autoComplete="off"
                onBlur={ closePopup }
            />
            { loadingSuggestions && <CircularProgress size={ 24 } /> }
            <Popover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={ open }
                anchorEl={ anchorEl }
                disableAutoFocus
                disableEnforceFocus
            >
                <Locations>
                    <List
                        data-automation="locations-list"
                    >
                        { suggestions.map((suggestion) => (
                            <Location
                                key={ suggestion.place_id }
                                onClick={ () => handleSuggestionClick(suggestion) }
                            >
                                <Icon.LocationIcon sx={ { marginRight: '16px' } } />
                                <ListItemText
                                    primary={
                                        <Street>
                                            { suggestion.structured_formatting.main_text }
                                        </Street>
                                    }
                                    secondary={
                                        <State>
                                            { suggestion.structured_formatting.secondary_text }
                                        </State>
                                    }
                                />
                            </Location>
                        )) }
                    </List>
                </Locations>
            </Popover>
        </>
    );

    const getPrivateResidenceField = () => (
        <RadioWrapper>
            <FormControl
                label="its a private residence"
                data-automation="private-residence"
                id="private-residence"

                control={
                    <Checkbox
                        size="small"
                        icon={
                            <Icon.RoundCheckBoxIcon
                                sx={ {
                                    width: '14px',
                                    height: '14px',
                                } }
                            />
                        }
                        checkedIcon={
                            <Icon.RoundCheckBoxFilledIcon
                                sx={ {
                                    width: '14px',
                                    height: '14px',
                                } }
                            />
                        }
                        checked={ privateResidence }
                        onChange={ handlePrivateResidenceChange }
                    />
                }
            />
        </RadioWrapper>
    );

    const getSomethingWrong = () => (
        <NoSlotsWrapper>
            <NoSlotsContainer>
                <Typography variant="body1" textAlign="center">
                    There seems to be an issue scheduling a pickup at the provided address.
                </Typography>

                <NoSlotsButton
                    fullWidth
                    small={ true }
                    variant="contained"
                    disableElevation
                    onClick={ onNextClick }
                >
                    Try Again
                </NoSlotsButton>
                {
                    shipmentMethod !== SchedulingType.DROPOFF && (
                        <>
                            <Typography variant="body1" color="divider">or</Typography>

                            <NoSlotsButton
                                variant="contained"
                                disableElevation
                                onClick={ setToDropOff }
                            >
                                Drop-Off Item Instead
                            </NoSlotsButton>
                        </>
                    )
                }

            </NoSlotsContainer>
        </NoSlotsWrapper>
    );

    const getForm = () => (
        <FormContainer>
            <FormWrapper>
                { getPhoneField() }
                { getAutoCompleteStreetField() }
                <TextInput
                    id="address-apt"
                    fullWidth
                    label="Apartment / suits No."
                    InputLabelProps={ { style: LabelStyle } }
                    data-automation="apt-input"
                    variant="standard"
                    value={ apt }
                    disabled={ privateResidence }
                    onChange={ handleAptChange }
                />
            </FormWrapper>
            { getPrivateResidenceField() }
        </FormContainer>
    );

    return (
        <>
            <SchedulingHeader isPC={ isPC } title="Enter Information" />

            { !isError ? getForm() : getSomethingWrong() }

            <BottomFixedCtaBtn
                variant="contained"
                color="primary"
                data-automation="shipping-confirm"
                sx={ {
                    marginTop: 'auto',
                    flex: '0 1 0',
                    alignSelf: 'stretch',
                    fontSize: '18px',
                    color: '#FFF !important',
                } }
                onClick={ () => void onNextClick() }
                disabled={ !street || (!apt && !privateResidence) || loadingForm || !!isError }
            >
                { loadingForm ? <CircularProgress size={ 24 } /> : 'Confirm Address' }
            </BottomFixedCtaBtn>
        </>
    );
};

export default AddressInput;
