import styled from '@emotion/styled';
import { Box, FileInput } from '@worthy-npm/worthy-common-ui-components';

export const UploadImgWrap = styled(FileInput, {
  shouldForwardProp: (prop) => !['bgImage', 'isLoading', 'disableUpload'].includes(prop),
})<{ bgImage?: string, isLoading: boolean, disableUpload?: boolean  }>(({ theme, bgImage, isLoading,disableUpload }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: disableUpload ? theme.palette.secondary.main : theme.palette.background.paper,
  backgroundColor: bgImage ? 'transparent' : (disableUpload ? theme.palette.background.default : '#B2CBFB'),
  backgroundImage: bgImage
    ? `radial-gradient(47.62% 66.71% at 95.83% 95%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.495676) 6.67%, rgba(0, 0, 0, 0.482245) 13.33%, rgba(0, 0, 0, 0.45917) 20%, rgba(0, 0, 0, 0.426294) 26.67%, rgba(0, 0, 0, 0.384113) 33.33%, rgba(0, 0, 0, 0.334058) 40%, rgba(0, 0, 0, 0.278654) 46.67%, rgba(0, 0, 0, 0.221346) 53.33%, rgba(0, 0, 0, 0.165942) 60%, rgba(0, 0, 0, 0.115887) 66.67%, rgba(0, 0, 0, 0.0737057) 73.33%, rgba(0, 0, 0, 0.0408299) 84%, rgba(0, 0, 0, 0.017755) 91%, rgba(0, 0, 0, 0.0043236) 100%, rgba(0, 0, 0, 0) 100%), url(${bgImage})`
    : 'none',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  alignSelf: 'center',
  width: '105px',
  height: '76px',
  borderRadius: '4px 4px 6px 6px',
  padding: '4px',
  ...(isLoading && {
    animation: 'pulsate 1.5s ease-in-out infinite',
    opacity: 0.5,
    '@keyframes pulsate': {
      '0%': {
        opacity: 0.5,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0.5,
      },
    },
  }),
}));

export const PhotosCountSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.background.paper,
  alignSelf: 'flex-end',
  marginTop: 'auto',
  '& svg': {
    height: '9px',
  },
}));

export const UploadPhotoBtn = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  color: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  maxWidth: '114px',
  width: '100%',
  height: '30px',
  borderRadius: '4px',
  padding: '6px',
  backgroundColor: theme.palette.tritary.main,
  '&:hover': {
    backgroundColor: theme.palette.tritary.main,
  },
  '&:active': {
    backgroundColor: theme.palette.tritary.main,
    boxShadow: 'none',
  },
}));
