import { Typography } from "@worthy-npm/worthy-common-ui-components";
import { Question } from "src/components/infoSections/faq";
import { getContentSiteUrl } from "src/config";



export  const SchedulingQuestions: Question[] = [
    {
        q: 'How does shipping work?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                Worthy will provide you with a prepaid shipping label and packing instructions. We can either schedule a
                free FedEx pickup at a time convenient for you, or you can drop off your package at a staffed FedEx location
                free of charge.
            </Typography>
        ),
    },
    {
        q: 'Do I have to pay for shipping?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                Nope! Worthy takes on the cost of shipping, and you pay absolutely nothing.
            </Typography>
        ),
    },
    {
        q: 'Can I back out of the process after shipping?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                <ul>
                    <li>
                        You can request to have your piece sent back to you at any point prior to the auction. Once your&nbsp;
                        <a href={ `${getContentSiteUrl()}/faqs/pricing/#reserve-price` }>
                            <Typography component="span" variant="body1" color="tritary.main" display="inline-block">
                                reserve price
                            </Typography>
                        </a>{' '}
                        is set and the auction begins, you cannot request a return. If the auction ends and your reserve price is
                        not met, you can have your piece sent back to you fully insured and free of charge.
                    </li>
                    <li>
                        If you would like to have your jewelry returned to you, please contact our support team who will
                        be able
                        to assist you further with our return process.
                    </li>
                </ul>
            </Typography>
        ),
    },
    {
        q: 'What should I include in my shipping box?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                <ul>
                    <li>
                        Your shipping box should include your jewelry and any protective accessories it requires. If the
                        piece you
                        would like to sell is from one of the{ ' ' }
                        <a href="https://www.worthy.com/about/what-we-accept" target="_blank" rel="noreferrer">
                            <Typography component="span" variant="body1" color="tritary.main" display="inline-block">
                                jewelry or watch brands we accept
                            </Typography>
                        </a>
                        , please send it to us with any boxes or paperwork you have to help authenticate the item.
                    </li>
                    <li>
                        Please note: For non-branded items (those purchased from a local jeweler or retailer), do not
                        include any
                        boxes or paperwork when shipping your piece. These boxes and paperwork will not be returned to
                        you.
                    </li>
                </ul>
            </Typography>
        ),
    },
    {
        q: 'What happens if my piece/s get lost or stolen?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                Your jewelry will be fully insured by insurers at Lloyd’s of London for up to $50K from the time you ship
                the item until it is either sold or returned to you. In the unlikely event that a loss occurs while the item
                is in our care, we will work with our insurers to file a claim and obtain reimbursement. See our&nbsp;
                <a href="https://www.worthy.com/about/terms-and-conditions" target="_blank" rel="noreferrer">
                    <Typography component="span" variant="body1" color="tritary.main" display="inline-block">
                        terms and conditions
                    </Typography>
                </a>
                &nbsp; for additional information about the insurance coverage and claims process.
            </Typography>
        ),
    },
    {
        q: 'Does Worthy insure my jewelry?',
        A: () => (
            <Typography
                component="span"
                variant="body1"
            >
                Our&nbsp;
                <a href="https://www.worthy.com/about/terms-and-conditions" target="_blank" rel="noreferrer">
                    <Typography component="span" variant="body1" color="tritary.main" display="inline-block">
                        insurance coverage
                    </Typography>
                </a>
                &nbsp; for a jewelry piece in transit is up to an amount of $50K<sup>*</sup> based on the resale replacement
                value and is subject to the terms, limitations and requirements of our then current insurance policy (the
                “Insurance Coverage”).
                <br />
                <br />
                <sup>*</sup> Jewelry pieces estimated above $50K will receive additional coverage with pre-approval from
                Worthy at our sole discretion.
            </Typography>
        ),
    },
];
