import { getAuthUrl } from 'src/config';
import axios, { AxiosInstance } from 'axios';
import { Item } from 'src/types/item.ts';

class AuthService {
    private auth: AxiosInstance;

    constructor() {
        this.auth = axios.create({
            baseURL: getAuthUrl(),
            withCredentials: true,
        });
    }

    async logout(id: number) {
        try {
            return this.auth.post<Item[] | []>(`/seller/${ id }/logout`);
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }
}

export const authService = new AuthService();
