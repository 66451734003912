import styled from '@emotion/styled';
import { Box, IconButton, Paper, Stack } from '@worthy-npm/worthy-common-ui-components';
import Button from '@worthy-npm/worthy-common-ui-components/components/Button';

const NewSubmitWrap = styled(Paper, {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen?: boolean; }>(({ theme, isOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '12px',
  backgroundColor: isOpen ? theme.palette.background.paper : 'transparent',
  boxShadow: isOpen ? 'inherit' : 'none',
  [theme.breakpoints.up('md')]: {
    margin: '0 auto 0 44px',
    maxWidth: '554px',
  },
}));


const SubmitNewItemContainer = styled(Stack)`
    display: flex;
    align-items: center;
    padding: 16px 12px;
    width: 100%;
    flex-direction: column;
    animation: fadeIn 1s;
`;

const NewSubmitTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
  justifyContent: 'space-between',
  alignSelf: 'stretch',
}));

const ItemWrap = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '8px',
  marginTop: '16px',
  width: '100%',
  maxWidth: '500px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const ItemCard = styled(Box, {
  shouldForwardProp: (prop) => !['selected'].includes(prop),
})<{ selected?: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  padding: '12px 16px 13px 16px',
  height: '54px',
  minWidth: '140px',
  borderRadius: '4px',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
  backgroundColor: selected ? theme.palette.background.secondary : theme.palette.background.paper,
  color: selected ? theme.palette.text.secondary : theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
  boxSizing: 'border-box',
  justifyContent: 'flex-start',
}));

const AddNewBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.text.secondary,
  marginTop: '16px',
  borderRadius: '4px',
  padding: '12px 24px 13px 24px',

  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabled,
    color: theme.palette.secondary.main,
  },
}));

const CloseIconBtn = styled(IconButton)(({ theme }) => ({
  width: '24px',
  height: '24px',
  bottom: '5px',
  left: '0',
  color: theme.palette.secondary.main,
}));

export {
  CloseIconBtn,
  NewSubmitWrap,
  SubmitNewItemContainer,
  NewSubmitTitle,
  ItemCard,
  ItemWrap,
  AddNewBtn,
};
