import { io, Socket } from 'socket.io-client';
import { getBackendUrl } from 'src/config';

const socket: Socket = io(getBackendUrl(), {
  withCredentials: true,
  reconnectionAttempts: 20,
  // transports: ['websocket'],
});

socket.io.on('error', (error) => {
  console.error('IO Error:', error);
});

socket.io.on('reconnect', (attempt) => {
  console.log(`IO Reconnected after ${attempt} attempts`);
});

socket.on('connect', () => {
  console.log('IO Connected to socket server');
});

socket.on('disconnect', (reason, details) => {
  console.log('IO Disconnected from socket server:', reason, details);
});

socket.onAny((event, ...args) => {
  console.log(`got ${event}`, args);
});

export default socket;
