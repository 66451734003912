import { PropsWithChildren } from 'react';
import Header from 'src/layout/nav/header.tsx';
import { Box, Drawer } from '@worthy-npm/worthy-common-ui-components';
import Navigation from 'src/layout/nav/navigation.tsx';
import { useAppSelector } from 'src/store';
import { isSchedulingOpen } from 'src/store/schedulingSlice';
import SchedulingPopUp from 'src/components/SchedulingPopUp/SchedulingPopUp';

const BaseLayout = (props: PropsWithChildren) => {
    const openReschedule = useAppSelector(isSchedulingOpen);
    return (
        <Box>
            <Header />
            <Navigation />
            <Box>{ props.children }</Box>
            {
                <Drawer anchor="bottom" open={ openReschedule }>
                    <SchedulingPopUp isPC={false} />
                </Drawer>
            }
        </Box>
    );
};

export default BaseLayout;
