import { useState } from 'react';

import {
    BasicModal,
    Divider,
    Icon,
    IconButton,
    Typography,
} from '@worthy-npm/worthy-common-ui-components';
import {
    Bullet,
    BulletWrapper,
    ButtonsContainer,
    Header,
    HeaderDesc,
    SkipBtn,
    StyledArrowIcon,
    SurveyContainer,
    TitleContainer,
} from 'src/styles/survey/survey.styles.tsx';
import { surveyQuestions } from 'src/helpers/survey.ts';
import SurveyConstructor from 'src/components/survey/surveyConstructor.tsx';
import { worthyService } from 'src/services/api/worthy.service.ts';
import { Item } from 'src/types/item.ts';
import { CtaButton } from '../ItemCard/StepsActions/StepsActions.styles';

interface TQuestion {
    title: string;
    type: string;
    scope: string;
    placeholder?: string;
    options: string[];
}

interface ISurvey {
    open: boolean;
    item: Item;
    onClose: () => void;
}

const Survey = ({ open, onClose, item }: ISurvey) => {
    const isGem = item.itemDescription?.toLowerCase().includes('gemstone');
    const questions: TQuestion[] = surveyQuestions(isGem, item.itemType);

    const [question, setQuestion] = useState(0);
    const [scope, setScope] = useState(['basic']);
    const [choice, setChoice] = useState<string>();
    const [flowQuestions, setFlowQuestions] = useState(questions);
    const [flow, setFlow] = useState<'gia' | 'additional' | ''>('');

    const handleChoice = (value: string) => {
        setChoice(value);
    };

    const onFlowSelect = (option: 'gia' | 'additional') => {
        const nextQuestion = questions.filter((q) => ['basic', option].includes(q.scope));
        setScope(['basic', option]);

        setFlowQuestions(nextQuestion);
        setFlow(option);

        setQuestion(question + 1);
    };

    const selectUpdateType = async (specField: string | undefined) => {
        if (!specField) return;

        if (flowQuestions[question].scope === 'gia') {
            await worthyService.updateCertificate(item.publicId, specField);
            return;
        }

        // important to have same values as in ui because of lowercase
        if (flowQuestions[question].title.includes('color')) {
            await worthyService.updateProperty(
                item.publicId,
                'color',
                specField?.toLowerCase(),
                isGem,
            );
            return;
        }

        if (flowQuestions[question].title.includes('clarity')) {
            await worthyService.updateProperty(item.publicId, 'clarity', specField?.toLowerCase());
            return;
        }

        if (flowQuestions[question].title.includes('carat')) {
            await worthyService.updateCaratWeight(item.publicId, specField);
            return;
        }

        await worthyService.submitSurveyAnswers(item.publicId, specField, flowQuestions[question].title);
    };

    const handleNext = () => {
        const nextQuestion = questions.filter((q) => scope.includes(q.scope));
        if (question < nextQuestion.length - 1) {
            if (!flow) setFlowQuestions(nextQuestion);
            // setFlowQuestions(nextQuestion);
            setQuestion(question + 1);
        }
        selectUpdateType(choice);
        setChoice('');

        if (question === nextQuestion.length - 1) {
            handleClose();
        }
    };

    const handleBack = () => {
        if (question > 0) {
            setQuestion(question - 1);
            setChoice('');
        }
    };

    const handleClose = () => {
        setQuestion(0);
        setScope(['basic']);
        setChoice('');
        setFlowQuestions(questions);
        setFlow('');
        onClose();
    };

    return (
        <BasicModal
            open={open}
            onClose={onClose}
            data-automation="survey-modal"
        >
            <SurveyContainer
                gem={isGem && flowQuestions[question].title.includes('color')}
                data-automation="survey-modal-content"
                expand={scope.includes('additional')}
            >
                <Header data-automation="survey-modal-header">
                    <IconButton
                        variant="default"
                        size="small"
                        color="secondary"
                        data-automation="survey-modal-back"
                        style={{
                            width: '24px',
                            height: '24px',
                            visibility: !question ? 'hidden' : 'visible',
                        }}
                        onClick={handleBack}
                    >
                        <StyledArrowIcon style={{ width: '24px', height: '24px' }} />
                    </IconButton>
                    <TitleContainer>
                        <Typography
                            data-automation="survey-modal-item-desc"
                            variant="button"
                            fontWeight="700"
                            lineHeight="18px"
                        >
                            {item.itemDescription}
                        </Typography>
                        <HeaderDesc
                            variant="caption"
                            color="text.primary"
                        >
                          Please answer a few more questions about your item.
                        </HeaderDesc>
                    </TitleContainer>
                    <IconButton
                        variant="default"
                        size="small"
                        color="secondary"
                        data-automation="survey-modal-close"
                        style={{ width: '24px', height: '24px' }}
                        onClick={handleClose}
                    >
                        <Icon.CloseIcon style={{ width: '24px', height: '24px' }} />
                    </IconButton>
                </Header>
                <Divider flexItem />
                <BulletWrapper>
                    {[
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        ...new Array(flowQuestions.filter((q) => scope.includes(q.scope)).length),
                    ].map((_, index) => (
                        <Bullet
                            data-automation="survey-modal-bullet"
                            active={index === question}
                            key={index}
                        />
                    ))}
                </BulletWrapper>
                <SurveyConstructor
                    isGem={isGem}
                    flow={flow}
                    title={flowQuestions[question].title}
                    type={flowQuestions[question].type}
                    scope={flowQuestions[question].scope}
                    options={flowQuestions[question].options}
                    placeholder={flowQuestions[question].placeholder}
                    onFlowSelect={onFlowSelect}
                    onNext={handleChoice}
                />

                <ButtonsContainer>
                    {questions[question].type !== 'button' && (
                        <CtaButton
                            fullWidth
                            disabled={!choice}
                            data-automation="survey-modal-next"
                            onClick={handleNext}
                        >
                            {question === flowQuestions.length - 1 ? 'Submit' : 'Next'}
                        </CtaButton>
                    )}

                    <SkipBtn
                        color="highlight.main"
                        data-automation="survey-modal-skip"
                        onClick={handleNext}
                    >
                        Skip
                    </SkipBtn>
                </ButtonsContainer>
            </SurveyContainer>
        </BasicModal>
    );
};

export default Survey;
