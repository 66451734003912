import {
  Box,
  Step,
  StepLabel,
  Typography,
  worthyShineTheme,
} from '@worthy-npm/worthy-common-ui-components';
import { Icon } from 'src/components/Icons';
import { StyledStepper } from './ProgressBar.styles';

interface ProgressBarProps {
  progressIndex: number;
  steps: string[];
  color: string;
  alert?: boolean;
}

function ProgressBar({ progressIndex, steps, color, alert }: ProgressBarProps) {
  const StepIcon = (index: number) => {
    const InheritedColor = index <= progressIndex ? color : worthyShineTheme.palette.divider;
    return (
      <Typography color={InheritedColor} fontSize="1rem">
        {progressIndex > index ? (
          <Icon.CheckIcon
            fontSize="inherit"
            color="inherit"
          />
        ) : (
          <Icon.RadioIcon
            fontSize="inherit"
            color="inherit"
            style={{ backgroundColor: 'transparent' }}
          />
        )}
      </Typography>
    );
  };
  return (
    <StyledStepper
      activeStep={progressIndex}
      alternativeLabel
      color={color}
      currentStepIndex={progressIndex}
    >
      <StepLabel classes={{ completed: 'Mui-completed' }}></StepLabel>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel StepIconComponent={() => StepIcon(index)} >{label}</StepLabel>
          {alert && index === progressIndex && (
            <Box
              position={'absolute'}
              zIndex={1}
              top={-3}
              left={-8}
            >
              <Icon.ReportProblemWhiteBg  />
            </Box>
          )}
        </Step>
      ))}
    </StyledStepper>
  );
}

export default ProgressBar;
