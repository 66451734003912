import { AppBar, Tabs } from '@worthy-npm/worthy-common-ui-components';
import styled from '@emotion/styled';

const StyledNavToolBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  width: '100%',
  transition: 'none',
  boxShadow: `0px 1.5px 0px 0px ${theme.palette.divider}`,
  marginTop: '1.5px',
  top: '65.5px',
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    top: '56.5px',
  },
}));

const TabsContainer = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-flexContainer': {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-evenly',
    },
  },
  boxShadow: `0px 1.5px 0px 0px ${theme.palette.divider}`,
  flex: 1,

  '.MuiTabs-indicator': {
    width: '176px',
    backgroundColor: theme.palette.highlight.main,
  },

  '.tab-wrapper': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 400,
    textTransform: 'none',
    padding: '16px 42px',

    '&.Mui-selected': {
      color: theme.palette.highlight.main,
      // '&::before': {
      //   content: '""',
      //   position: 'relative',
      //   width: '6px',
      //   height: '6px',
      //   borderRadius: '50%',
      //   backgroundColor: theme.palette.highlight.main,
      //   left: '65%',
      //   top: '35%',
      //   transform: 'translateY(-50%)',
      //   [theme.breakpoints.down('md')]: {
      //     left: '42%',
      //     transform: 'translateY(-42%)',
      //   },
      //
      //   [theme.breakpoints.down('sm')]: {
      //     left: '56%',
      //     transform: 'translateY(-56%)',
      //   },
      // },
    },
  },
}));

export { StyledNavToolBar, TabsContainer };
