import {
    auctionStates,
    WorkflowStatus,
    processingStates,
    shippingStates,
    SchedulingType,
} from './item.constants';
import PickupStep from 'src/components/ItemCard/StepsActions/PickupStep';
import { FC } from 'react';
import DropOffStep from 'src/components/ItemCard/StepsActions/DropOffStep';
import InTransitStep from 'src/components/ItemCard/StepsActions/InTransitStep';
import PrepForSaleStep from 'src/components/ItemCard/StepsActions/PrepForSaleStep';
import RejectAtTheDoorStep from 'src/components/ItemCard/StepsActions/RejectAtTheDoorStep';
import ReadyForAuctionStep from 'src/components/ItemCard/StepsActions/ReadyForAuctionStep';
import AbortedStep from 'src/components/ItemCard/StepsActions/AbortedStep';
import AuctionEndedStep from 'src/components/ItemCard/StepsActions/AuctionEndedStep';
import LiveAuctionStep from 'src/components/ItemCard/StepsActions/LiveAuctionStep';
import { Item } from 'src/types/item.ts';
import PickUpSettingMenu from 'src/components/ItemCard/StepsActions/PickupSettingMenu';
import GetOfferStep from 'src/components/ItemCard/StepsActions/GetOfferStep';
import AuctionScheduledStep from 'src/components/ItemCard/StepsActions/AuctionScheduledStep';
import { PrepareForPickupDropOffDesktop } from 'src/views/PrepareForPickupDropOffDesktop';
import ArrivedStep from 'src/components/ItemCard/StepsActions/ArrivedStep';


export const pendingReason = (status: Item['status'], isPhoto: boolean) => {
    switch (status) {
        case WorkflowStatus.PENDING_APPROVAL:
            return {
                title: 'Approval pending',
                description: 'Our experts are currently reviewing your photo(s). Upon approval, your FedEx shipping options will become available.',
            };
        case WorkflowStatus.PENDING:
            if (!isPhoto) {
                return {
                    title: 'Photo required',
                    description: 'Please add a clear photo of your item to continue with the selling process. Our experts will review your photo(s) and once approved, your FedEx shipping options will become available.',
                };
            }
            return {
                title: 'Approval pending',
                description: 'Our experts are currently reviewing your photo(s). Upon approval, your FedEx shipping options will become available.',
            };
        case WorkflowStatus.PENDING_IMAGE_UPLOAD:
            return {
                title: 'Photo required',
                description: 'Please add a clear photo of your item to continue with the selling process. Our experts will review your photo(s) and once approved, your FedEx shipping options will become available.',
            };
    }
    return null;
};

const AlertStatuses = [
    WorkflowStatus.REJECTED_AFTER_AUCTION,
    WorkflowStatus.REJECTED_IN_PREPARATION,
    WorkflowStatus.QUICK_SALE,
    WorkflowStatus.RETURNED,
    WorkflowStatus.NOT_ARRIVED,
    WorkflowStatus.REJECTED_WITHOUT_SHIPBACK
];

const ShippingStatusesToIndex:Partial<Record<WorkflowStatus, number>> = {
    [WorkflowStatus.SCHEDULING]: 0,
    [WorkflowStatus.SHIPPING_SCHEDULED]: 1,
    [WorkflowStatus.IN_TRANSIT]: 2,
    [WorkflowStatus.NOT_ARRIVED]: 3,
    [WorkflowStatus.REJECTED_WITHOUT_SHIPBACK]: 3,
};
const ProcessingStatusesToIndex:Partial<Record<WorkflowStatus, number>>  = {
    [WorkflowStatus.ARRIVED]: 1,
    [WorkflowStatus.PREP_FOR_SALE]: 2,
    [WorkflowStatus.REJECTED_IN_PREPARATION]: 2,
    [WorkflowStatus.QUICK_SALE]: 2,
    [WorkflowStatus.RETURNED]: 1,
};

const AuctionStatusesToIndex:Partial<Record<WorkflowStatus, number>> = {
    [WorkflowStatus.READY_FOR_AUCTION]: 1,
    [WorkflowStatus.LIVE_AUCTION]: 2,
    [WorkflowStatus.AUCTION_ENDED]: 3,
    [ WorkflowStatus.REJECTED_AFTER_AUCTION]: 3,
    [WorkflowStatus.DEAL]: 3,
    [WorkflowStatus.AUCTION_SCHEDULED]: 1,
};



export const ProgreeBarOptions = ({
    item,
}: {
    item: Item;
}): { color: string; steps: string[]; title: string; currentStepIndex: number; alert: boolean } => {
    let steps: string[] = [];
    let title = '';
    let color = '';
    const alert = AlertStatuses.includes(item.status);
    let currentStepIndex = -1;

    if (shippingStates.includes(item.status)) {
        color = '#AB7C8A';
        steps = [
            item?.schedulingDetails?.type === SchedulingType.PICKUP ? 'Scheduled' : 'Confirmed',
            item?.schedulingDetails?.type === SchedulingType.PICKUP ? 'Pickup' : 'Drop off',
            'In transit',
            'Delivered',
        ],
        title = 'Shipping';
        currentStepIndex = ShippingStatusesToIndex[item.status] ?? -1;
    } else if (processingStates.includes(item.status)) {
        color = '#DCA961';
        steps = [
            'Arrived',
            'Prep for sale',
            'Ready for auction',
        ];
        title = 'Processing';
        currentStepIndex = ProcessingStatusesToIndex[item.status] ?? -1;
    } else if (auctionStates.includes(item.status)) {
        color = '#73A0F7';
        steps = [
            'Ready for auction',
            'Auction Started',
            'Auction Ended',
        ];
        title = 'Auction';
        currentStepIndex = AuctionStatusesToIndex[item.status] ?? -1;
    }
    return {
        alert,
        color,
        steps,
        title,
        currentStepIndex,
    };
};

export const StepInfoObject = ({
    items,
}: {
    items: Item[];
}): {
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ActionComponent?: FC<any>  | undefined;
    ActionMenuComponent?: FC<{items: Item[]}> | undefined;
    ActionTriggeredComponent?: ({ item, onClose }: {item: Item, onClose: ()=> void}) => JSX.Element | undefined;
} => {
    const item = items[0];
    switch (item.status) {
        case WorkflowStatus.SHIPPING_SCHEDULED:
            return item.schedulingDetails?.type === SchedulingType.PICKUP
                ? {
                      title: 'Pickup successfully scheduled',
                      ActionComponent: PickupStep,
                      ActionMenuComponent: PickUpSettingMenu,
                      ActionTriggeredComponent: PrepareForPickupDropOffDesktop
                  }
                : {
                      title: 'FedEx drop off confirmed',
                      ActionComponent: DropOffStep,
                      ActionMenuComponent: PickUpSettingMenu,
                      ActionTriggeredComponent: PrepareForPickupDropOffDesktop
                  };
        case WorkflowStatus.IN_TRANSIT:
            return {
                title: 'Your item is on the way',
                ActionComponent: InTransitStep,
            };
        case WorkflowStatus.PREP_FOR_SALE:
            return {
                title: 'We are preparing your items for auction',
                ActionComponent: PrepForSaleStep,
            };
        case WorkflowStatus.QUICK_SALE:
                return {
                    title: 'Item eligible for direct buy',
                    ActionComponent: GetOfferStep,
            };
        case WorkflowStatus.ARRIVED:
            return {
                title: 'We’ve received your item',
                ActionComponent: ArrivedStep,
            };
        case WorkflowStatus.REJECTED_IN_PREPARATION:
            return {
                title: 'Item not eligible for auction',
                ActionComponent: RejectAtTheDoorStep,
            };
        case WorkflowStatus.READY_FOR_AUCTION:
            return {
                title: 'Your item is ready for auction!',
                ActionComponent: ReadyForAuctionStep,
            };
        case WorkflowStatus.AUCTION_SCHEDULED:
                return {
                    title: 'Auction scheduled!',
                    ActionComponent: AuctionScheduledStep,
                };
        case WorkflowStatus.LIVE_AUCTION:
            return {
                title: 'Your auction has started!',
                ActionComponent: LiveAuctionStep,
            };
        case WorkflowStatus.AUCTION_ENDED:
            return {
                title: 'Your auction has ended',
                ActionComponent: AuctionEndedStep,
            };
        case WorkflowStatus.DEAL:
            return {
                title: 'Your auction has ended',
                ActionComponent: AuctionEndedStep,
            };
        case WorkflowStatus.REJECTED_AFTER_AUCTION:
            return {
                title: 'Your auction has ended',
                ActionComponent: AbortedStep,
            };
        case WorkflowStatus.NOT_ARRIVED:
            return {
                title: 'This item has not arrived at our office',
            };
        default:
            return {
                title: item.status,
            };
    }
};
