import { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from 'src/store/createAppSlice.ts';

// data connected not to user but to service itself
interface SharedState {
  route: string;
}

const initialState: SharedState = {
  route: '/shipping',
};

export const sharedSlice = createAppSlice({
  name: 'shared',
  initialState,
  reducers: (create) => ({
    setRoute: create.reducer((state, action: PayloadAction<string>) => {
      state.route = action.payload;
    }),
  }),

  selectors: {
    getRoute: (state) => state.route,
  },
});

export const { getRoute } = sharedSlice.selectors;
export const { setRoute } = sharedSlice.actions;
