import Rollbar from 'rollbar';
import type { Dictionary } from 'rollbar';
import { getRollbarToken, getRuntimeType } from 'src/config';

if (getRuntimeType() !== 'local') {
    window.rollbar = new Rollbar({
        accessToken: getRollbarToken(),
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            environment: getRuntimeType(),
        },
        checkIgnore: (isUncaught, args, payload: Dictionary) => payload.environment === 'local',
    });
}
