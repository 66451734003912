/* eslint-disable @typescript-eslint/no-misused-promises */

import { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'src/components/Icons.tsx';
import { BottomFixedCtaBtn } from 'src/styles/SchedulingCTA/SchedulingCTA.styles.tsx';
import {
    Container,
    AddressContainer,
    AddressWrapper,
    Address,
    SlotsWrapper,
    Slot,
    MonthWrapper,
    DayStr,
    TimeWrapper,
    Time,
    TimeStr,
    NoSlotsContainer,
    NoSlotsWrapper,
    NoSlotsButton, MonthDayStr, NavIcon,
} from 'src/components/PickupSlots/PickupSlots.styles.tsx';
import { Box, CircularProgress, Typography } from '@worthy-npm/worthy-common-ui-components';
import {
    getMainItemPublicId,
    getFormattedAddress,
    getLocations,
    getPickupAvailability,
    getScheduledDate,
    getScheduledTime,
    getUserLocalTime,
    nextStep,
    setDate,
    setFlowToStart,
    setTime,
    setTimeRange,
    setError,
    getError,
    setShippingMethod,
    schedulePickup,
    setLoading as setLoad,
} from 'src/store/schedulingSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { prepareDropOffLocations } from 'src/helpers/scheduling/common.ts';
import { PickupAvailability } from 'src/types/shipping.ts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { PopupDivider } from 'src/styles/SchedulingPopup/SchedulingPopup.styles.tsx';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';
import { DropOffLocations } from 'src/services/api/worthy.service.ts';
import { getDayWithSuffix, sendUserEvent } from 'src/helpers/common.ts';
import SchedulingHeader from 'src/components/SchedulingPopUp/schedulingHeader.tsx';
import { SchedulingType } from 'src/constants/item.constants.tsx';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(isSameOrAfter);

export interface Slot {
    dayText: string;
    readyTimestamp: string;
    timeText: string;
    companyCloseTime: string;
    earlySlot: boolean;
}

export interface Day {
    dayText: string;
    dayNum: string;
    monthText: string;
    date: string;
    slots: Slot[];
}

const roundTimeWithDayjs = (time: dayjs.Dayjs, interval: number) => {
    const min = time.minute();
    const remainder = min % interval;
    return remainder === 0
        ? time.second(0).millisecond(0)
        : time.add(interval - remainder, 'minutes').second(0).millisecond(0);
};

const buildSlots = (
    pickupAvailability: PickupAvailability[],
    localTime: string,
    ignoreLocalTime = false,
): Day[] => {
    if (!pickupAvailability?.length || !localTime) {
        console.error(
            `Missing required field pickupAvailability.length: ${ pickupAvailability.length } or localTime: ${ localTime }`,
        );
        return [];
    }

    //todo: use actual client time
    const clientLocalTime = !ignoreLocalTime ? dayjs().tz('America/New_York').format() : localTime;

    localTime = clientLocalTime || localTime;

    const PICKUP_START_TIME = 11;
    const MIN_PICKUP_START_TIME_THRESHOLD = 9;
    const FEDEX_OFFICIAL_START_TIME = 13;
    const MAX_SCHEDULE_PICKUP_HOURS = 48;
    const maxSchedulePickupTime = dayjs(localTime).add(MAX_SCHEDULE_PICKUP_HOURS, 'hours');
    const userLocalTime = dayjs(localTime);

    const groupedSlotsByDay: Day[] = [];

    pickupAvailability.forEach((pickup) => {
        const [ cutOffHour, cutOffMinute ] = pickup.cut_off_time.split(':').map(Number);
        const cutOffTime = cutOffHour;
        const leftMinutes = cutOffMinute;
        const accessTime = pickup.access_time;
        const date = pickup.date;

        const startTime =
            cutOffTime <= FEDEX_OFFICIAL_START_TIME
                ? MIN_PICKUP_START_TIME_THRESHOLD
                : PICKUP_START_TIME;

        const time =
            dayjs(date).isSame(userLocalTime, 'day') && userLocalTime.hour() >= startTime
                ? userLocalTime.hour() + 1
                : startTime;

        let pickupSlot = roundTimeWithDayjs(
            dayjs.tz(
                `${date}T${userLocalTime.format('HH:mm:ss')}`,
                'YYYY-MM-DDTHH:mm:ss',
                'America/New_York'
            ),
            60
        );

        pickupSlot = pickupSlot.set('hour', time);

        let readyTimestamp = pickupSlot;
        let companyCloseTime = pickupSlot.add(accessTime, 'seconds');

        const slotsForDay: Slot[] = [];

        while (companyCloseTime.hour() <= cutOffTime) {
            if (
                companyCloseTime.isAfter(maxSchedulePickupTime) &&
                slotsForDay.length > 0 &&
                !readyTimestamp.isSame(
                    dayjs(slotsForDay[slotsForDay.length - 1].readyTimestamp),
                    'day',
                )
            ) {
                break;
            }

            if (companyCloseTime.hour() === cutOffTime && companyCloseTime.minute() < leftMinutes) {
                companyCloseTime = companyCloseTime.set('minute', leftMinutes);
            }

            if (
                ignoreLocalTime ||
                (readyTimestamp.isAfter(userLocalTime) &&
                    companyCloseTime.isAfter(userLocalTime.add(2, 'hours')))
            ) {
                const slot: Slot = {
                    dayText: readyTimestamp.format('ddd'), // Abbreviated day, e.g., "Mon"
                    readyTimestamp: readyTimestamp.format('YYYY-MM-DDTHH:mm:ss'),
                    timeText: `${ readyTimestamp.format('h:mma') } - ${ companyCloseTime.format('h:mma') }`,
                    companyCloseTime: companyCloseTime.format('HH:mm:ss'),
                    earlySlot: readyTimestamp.hour() < FEDEX_OFFICIAL_START_TIME,
                };
                slotsForDay.push(slot);
            }

            const futureTime = companyCloseTime.add(accessTime, 'seconds');
            if (companyCloseTime.hour() < cutOffTime && futureTime.hour() > cutOffTime) {
                companyCloseTime = companyCloseTime.set('hour', cutOffTime);
                readyTimestamp = companyCloseTime.subtract(accessTime, 'seconds');
            } else {
                readyTimestamp = companyCloseTime;
                companyCloseTime = futureTime;
            }
        }

        if (slotsForDay.length > 0) {
            groupedSlotsByDay.push({
                dayText: readyTimestamp.format('ddd'), // "Mon", "Tue", etc.
                dayNum: readyTimestamp.format('D'),
                monthText: readyTimestamp.format('MMM'), // "Sep", "Oct", etc.
                date: date,
                slots: slotsForDay,
            });
        }
    });

    return groupedSlotsByDay;
};

const PickupSlots = ({isPC}:{isPC: boolean}) => {
    const dispatch = useAppDispatch();

    const userLocalTime = useAppSelector(getUserLocalTime);
    const address: string = useAppSelector(getFormattedAddress);
    const pickupAvailability: PickupAvailability[] = useAppSelector(getPickupAvailability);
    const shipmentLocations = useAppSelector(getLocations);
    const date: string = useAppSelector(getScheduledDate);
    const time: string = useAppSelector(getScheduledTime);
    const mainItemPublicId = useAppSelector(getMainItemPublicId);
    const isError = useAppSelector(getError);

    const [ days, setDays ] = useState<Day[]>([]);
    const [ selectedDate, setSelectedDate ] = useState<string>(date);
    const [ selectedTime, setSelectedTime ] = useState<string>(time);
    const [ loading, setLoading ] = useState<boolean>(false);

    const dateContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchSlots = () => {
            setLoading(true);
            try {
                const slots = buildSlots(pickupAvailability, userLocalTime);
                setDays(slots);
                selectedDate || setSelectedDate(slots[0]?.date); // default selected date to first available date
                const dayAndTimeList = slots
                    .map(
                        (day) =>
                            `${ day.dayText } ${ day.slots.map((slot) => slot.timeText).join(', ') }`,
                    )
                    .join(', ');
                sendUserEvent(
                    `Pickup time slots shown to the seller ${ slots.length }: ${ dayAndTimeList }`,
                    mainItemPublicId,
                );
            } catch (error) {
                console.error('Error fetching slots', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSlots();
    }, []);

    const handleDateClick = (date: string) => {
        setSelectedDate(date);
        setSelectedTime(''); // reset time selection when switching dates
    };

    const handleTimeClick = (clickedTime: string, clickedDate: string) => {
        setSelectedTime(clickedTime);
        setSelectedDate(clickedDate);
    };

    const scrollLeft = () => {
        if (dateContainerRef.current) {
            dateContainerRef.current.scrollBy({ left: -190, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (dateContainerRef.current) {
            dateContainerRef.current.scrollBy({ left: 190, behavior: 'smooth' });
        }
    };

    const getChosenSlot = () =>
        days
            .find((day) => day.date === selectedDate)
            ?.slots.find((slot) => slot.readyTimestamp === selectedTime);

    const handlePickupSchedule = async () => {
        setLoading(true);
        try {
            const locations: DropOffLocations = await prepareDropOffLocations(shipmentLocations);
            const chosenSlot = getChosenSlot();
            const slotCompanyCloseTime = chosenSlot?.companyCloseTime;

            if (!chosenSlot || !slotCompanyCloseTime) {
                throw new Error('Company close time not found');
            }

            await dispatch(schedulePickup({
                itemPublicId: mainItemPublicId,
                readyTimestamp: selectedTime,
                companyCloseTime: slotCompanyCloseTime,
                dropOffLocations: locations,
            }));

            if (chosenSlot.earlySlot) {
                sendUserEvent(
                    `User scheduled early pickup. readyTimestamp: ${ chosenSlot.readyTimestamp } companyCloseTime: ${ slotCompanyCloseTime }`,
                    mainItemPublicId,
                );
            }
            dispatch(setError(''));
            dispatch(nextStep());
        } catch (error) {
            dispatch(setError('Error scheduling pickup'));
            //TODO: add error handling
            console.error('Error scheduling pickup', error);
        } finally {
            setLoading(false);
            dispatch(setLoad(false));
        }
    };

    const onBtnClick = async () => {
        dispatch(setDate(selectedDate));
        dispatch(setTime(selectedTime));
        await handlePickupSchedule();
    };

    const resetErrors = () => {
        dispatch(setLoad(false));
        dispatch(setError(''));
    }

    const tryAgain = () => {
        return handlePickupSchedule();
    }

    const setToDropOff = () => {
        dispatch(setShippingMethod(SchedulingType.DROPOFF));
        dispatch(setFlowToStart());
        resetErrors();
    };

    const getAddress = () => (
        <AddressContainer>
            <AddressWrapper>
                <Box
                    sx={ {
                        display: 'flex',
                        alignItems: 'center',
                        gap: worthyShineTheme.spacing(2),
                        flex: '1 0 0',
                    } }
                >
                    <Icon.LocationIcon
                        sx={ {
                            width: '12px',
                            height: '18px',
                        } }
                    />

                    <Address>{ address }</Address>
                </Box>
            </AddressWrapper>
        </AddressContainer>
    );

    const getTimes = (day: Day) => (
        <TimeWrapper key={ `${ day.date }-slots` }>
            { day.slots.map((slot) => {
                return (
                    <Time
                        id={ `${ day.date }-time` }
                        key={ `${ day.date }-${ slot.timeText }` }
                        onClick={ () => {
                            handleTimeClick(slot.readyTimestamp, day.date);
                            dispatch(setTimeRange(slot.timeText));
                        } }
                        selected={ selectedTime === slot.readyTimestamp }
                    >
                        <TimeStr
                            selected={ selectedTime === slot.readyTimestamp }
                            variant="body1"
                        >
                            { slot.timeText }
                        </TimeStr>
                    </Time>
                );
            }) }
        </TimeWrapper>
    );

    const getDats = (day: Day) => {
        const isSelectedDay = day.date === selectedDate;

        return (
            <MonthWrapper
                id="month-header"
                key={ `${ day.date }-header` }
                onClick={ () => handleDateClick(day.date) }
                selected={ isSelectedDay }
            >
                <MonthDayStr>{ day.monthText } { getDayWithSuffix(+day.dayNum) }</MonthDayStr>
                <DayStr>{ day.dayText }</DayStr>

            </MonthWrapper>

        );
    };

    const getSlots = () => (
        <>
            <NavIcon
                size="small"
                onClick={ scrollLeft }
            >
                <Icon.ExpandMoreShineIcon style={ { transform: 'rotate(90deg)', width: '12px', height: '6px' } } />
            </NavIcon>
            <SlotsWrapper
                ref={ dateContainerRef }
                id="slot-wrapper"
            >
                { days.map((day) => {
                    return (
                        <Slot key={ `${ day.date }-slot-wrapper` }>
                            { getDats(day) }
                            { getTimes(day) }
                        </Slot>
                    );
                }) }
            </SlotsWrapper>
            <NavIcon
                size="small"
                onClick={ scrollRight }
                next={ true }
            >
                <Icon.ExpandMoreShineIcon style={ { transform: 'rotate(270deg)', width: '12px', height: '6px' } } />
            </NavIcon>
        </>

    );

    const getNoPickupsAvailableMsg = useCallback(() => {
        return (
            <NoSlotsWrapper>
                <NoSlotsContainer>
                    <Typography variant="body1" textAlign="center">
                        Currently there is no available slots for <br />pickup in the next days,<br />
                        you can try again in a few days
                    </Typography>

                    <Typography variant="body1" color="divider">or</Typography>

                    <NoSlotsButton
                        variant="contained"
                        disableElevation
                        onClick={ setToDropOff }
                    >
                        Drop-off item instead
                    </NoSlotsButton>
                </NoSlotsContainer>
            </NoSlotsWrapper>
        )
    }, [setToDropOff]);

    const getSomethingWrong = useCallback(() => {
        return (
            <NoSlotsWrapper>
                <NoSlotsContainer>
                    <Typography variant="body1" textAlign="center">
                        There seems to be an issue scheduling a pickup at the provided address.
                    </Typography>

                    <NoSlotsButton
                        fullWidth
                        small={ true }
                        variant="contained"
                        disableElevation
                        onClick={ tryAgain }
                    >
                        Try again
                    </NoSlotsButton>

                    <Typography variant="body1" color="divider">or</Typography>

                    <NoSlotsButton
                        variant="contained"
                        disableElevation
                        onClick={ setToDropOff }
                    >
                        Drop-off item instead
                    </NoSlotsButton>
                </NoSlotsContainer>
            </NoSlotsWrapper>
        );
    }, [tryAgain, setToDropOff]);

    return (
        <>
            <Container>
                <SchedulingHeader isPC={ isPC }
                                  title={ (isError || !days.length) ? 'Unable to schedule FedEx pickup' : 'Pickup time' }
                />
                { !isError && getAddress() }
                { !isError && <PopupDivider orientation="vertical" /> }
                { isError ? getSomethingWrong() : (days.length > 0 ? getSlots() : getNoPickupsAvailableMsg()) }
                <BottomFixedCtaBtn
                    variant="contained"
                    color="primary"
                    sx={ {
                        alignSelf: 'stretch',
                        fontSize: '18px',
                        color: `${ worthyShineTheme.palette.common.white } !important`,
                        backgroundColor: selectedTime
                            ? `${ worthyShineTheme.palette.highlight.main }`
                            : `${ worthyShineTheme.palette.action.disabled }`,
                    } }
                    onClick={ () => void onBtnClick() }
                    disabled={ !selectedDate || !selectedTime || loading || !!isError }
                >
                    { loading ? <CircularProgress size={ 24 } /> : 'Confirm Pickup Time' }
                </BottomFixedCtaBtn>
            </Container>
        </>
    );
};
export default PickupSlots;
