import TableHead from '@worthy-npm/worthy-common-ui-components/components/TableHead'
import TableRow from '@worthy-npm/worthy-common-ui-components/components/TableRow'
import TableCell from '@worthy-npm/worthy-common-ui-components/components/TableCell'
import Typography from '@worthy-npm/worthy-common-ui-components/components/Typography'
import TableBody from '@worthy-npm/worthy-common-ui-components/components/TableBody'
import { styled, Table } from '@worthy-npm/worthy-common-ui-components'

const StyledTable = styled(Table)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    maxWidth: 400,
    margin: theme.spacing(1, 0),
    '& th, td': {
      backgroundColor: theme.palette.primary.main,
      color: 'inherit',
      border: `2px solid ${theme.palette.primary.main}`,
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
    '& td': {
      backgroundColor: 'transparent',
    },
  }))
  
const tableData = [
    {
      winningBid: 'Up to $5,000',
      fee: '18%',
    },
    {
      winningBid: '$5,001 - $15,000',
      fee: '14%',
    },
    { winningBid: '$15,001 - $30,000', fee: '12%' },
    { winningBid: '$30,001 - above', fee: '10%' },
  ]

export function FeesTable() {
  return (
    <>
      <StyledTable aria-label="fees table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body1" fontWeight="bold">
                Winning bid
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" fontWeight="bold">
                Fee
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={row.winningBid}>
              <TableCell>
                <Typography variant="body1" fontWeight="light">
                  {row.winningBid}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="light">
                  {row.fee}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <Typography variant="caption" color="secondary">
        ** For Jewelry Box (3 or more valuables), a flat fee of 18%* applies.
      </Typography>
    </>
  )
}
