import styled from '@emotion/styled';
import { Box } from '@worthy-npm/worthy-common-ui-components';
import Button from '@worthy-npm/worthy-common-ui-components/components/Button';

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '326px',
  maxWidth: '50vw',
  border: `1.5px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  padding: '16px',
  gap: '16px',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));

const SubmitBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.text.secondary,
  alignSelf: 'stretch',

  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabled,
    color: theme.palette.secondary.light,
  },
}));

export {
  ModalContainer,
  SubmitBtn
};
