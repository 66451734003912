// !!!!!questions are important even case change can break submit!!!!!!!
export const surveyQuestions = (isGem: boolean, itemType: string) => {
    const fullItems = ['loose diamond', 'ring'];

    const timingQuestion = {
        title: `When are you likely to sell your ${itemType?.toLowerCase()}?`,
        type: 'select',
        scope: 'basic',
        options: ['Next 30 days', 'Next 3 months', 'Within 1 year', 'Not sure'],
    };

    const lastGeneral = {
        title: `Is there anything else you would like to tell us about your ${itemType?.toLowerCase()}?`,
        scope: 'basic',
        type: 'textarea',
        options: [],
    };

    if (isGem) {
        return [
            timingQuestion,
            {
                title: 'Whats the center diamond’s color grade?',
                scope: 'basic',
                type: 'select',
                options: [
                    'Black',
                    'Colorless',
                    'Purple',
                    'Blue',
                    'Brown',
                    'Gray',
                    'Green',
                    'Orange',
                    'Pink',
                    'Red',
                    'White',
                    'Yellow',
                    'Multicolored',
                    'NA',
                ],
            },
            lastGeneral,
        ];
    }

    if (!fullItems.includes(itemType?.toLowerCase())) {
        return [
            timingQuestion,
            lastGeneral,
        ];
    }

    return [
        timingQuestion,
        {
            title: 'Do you have a GIA report?',
            type: 'button',
            scope: 'basic',
            options: ["No", 'Yes'],
        },
        {
            title: 'If you know the GIA report number, please enter it',
            scope: 'gia',
            type: 'input',
            placeholder: 'enter 6-10 digit number',
            options: [],
        },
        {
            title: 'Whats the center diamond’s color grade?',
            scope: 'additional',
            type: 'select',
            options: ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M-Z', 'Fancy', 'Not sure'],
        },
        {
            title: 'Whats the center diamond clarity grade?',
            scope: 'additional',
            type: 'select',
            options: [
                'FL',
                'IF',
                'VVS1',
                'VVS2',
                'VS1',
                'VS2',
                'SI1',
                'SI2',
                'I1',
                'I2',
                'I3',
                'Not sure',
            ],
        },
        {
            title: 'What is the carat weight of the largest diamond?',
            scope: 'additional',
            type: 'input',
            placeholder: 'Enter carat weight (0.0 ct.)',
            options: [],
        },
        lastGeneral,
    ];
};
