import {  Box, Button, Icon, Stack, Typography,styled } from "@worthy-npm/worthy-common-ui-components";
import { IconButton } from "@mui/material";
import { useState, useRef } from "react";
import ItemCardDetails from "src/components/ItemCard/ItemCardDetails";
import { Item } from "src/types/item";
import { StyledInnerContainer } from "./ItemCardBundleList";

interface ItemCardBundleListProps {
    items: Item[];
}

const StyledListContainer = styled('div', { shouldForwardProp: (prop) => ![ 'expandedItemList' ].includes(prop as string) })<{expandedItemList: boolean}>(({ theme, expandedItemList }) => ({
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    gap: 'inherit',
    padding: theme.spacing(1.5),
    flexWrap: expandedItemList ? 'wrap' : 'nowrap',
    scrollBehavior: 'smooth',
}));


const BundleItemsListCarousel = ({ items }:ItemCardBundleListProps) => {
    const [expandedItemList, setExpandedItemList] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    
    const handleItemListToggle = () => {
        setExpandedItemList(!expandedItemList);
    };
    const scrollLeft = () => {
        if (containerRef.current) {
            console.log('scrollLeft',containerRef.current );
          containerRef.current.scrollLeft -= 500; // Adjust the scroll amount as needed
        }
      };
    
      const scrollRight = () => {
        if (containerRef.current) {
            console.log('scrollRight',containerRef.current );
          containerRef.current.scrollLeft += 500; // Adjust the scroll amount as needed
        }
      };
    return (<>
        <Stack  gap={2}>
            <StyledListContainer ref={containerRef}  expandedItemList={expandedItemList}>
                {items.map(item => (
                    <StyledInnerContainer key={item.publicId}  sx={{ minWidth:400, maxWidth: 400}}>
                        <ItemCardDetails
                            item={item}
                        />
                    </StyledInnerContainer>
                ))}
            </StyledListContainer>
                <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'} height={22} >
                    <Typography variant={'body1'} color={'secondary'}></Typography>
                    <Stack width={120} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                        {!expandedItemList && items.length > 2 && (<IconButton onClick={()=> scrollLeft()} size="small" edge='start' sx={{transform: "rotate(90deg)", fontSize: 'meduim'}}><Icon.ExpandMoreShineIcon color="inherit" fontSize="inherit" /></IconButton>)}
                        {items.length} items
                        {!expandedItemList && items.length > 2 && (<IconButton onClick={()=> scrollRight()} size="small" edge='end' sx={{transform: "rotate(270deg)", fontSize: 'meduim'}}><Icon.ExpandMoreShineIcon color="inherit" fontSize="inherit"/> </IconButton>)}
                    </Stack>
                    <Box>
                        {(items.length > 2) && (
                            <Button variant="text" size="small" color="highlight" onClick={handleItemListToggle}>
                                <Typography variant="body1" fontWeight={600}>
                                    {expandedItemList ? 'See less items' : 'See all items'}
                                </Typography>
                            </Button>
                        )}
                    </Box>

                </Stack>
        </Stack>
    </>);
}

export default BundleItemsListCarousel;