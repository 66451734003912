import { Box, Paper, Stack, Typography } from '@worthy-npm/worthy-common-ui-components';
import _ from 'lodash';
import { pendingSchedulingStates, preScheduleStates } from 'src/constants/item.constants';
import { Item } from 'src/types/item.ts';
import styled from '@emotion/styled';
import React from 'react';
import { ItemSkeleton, SchedulingListWrapper } from 'src/components/SchedulingMethods/SchedulingMethods.styles.tsx';
import ItemCardDetails from 'src/components/ItemCard/ItemCardDetails';

const ItemGroups = {
    Pending: 'Pending',
    Valid: 'Valid',
} as const;

const EmptyStateMessages: Record<keyof typeof ItemGroups, string> = {
    Valid: 'Items approved for shipping will appear here.',
    Pending: '',
};
const EmptyMsgWrapper = styled(Stack)`
    border-radius: 8px;
    padding: 24px 0;
    height: 86px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 16px 0px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D2D5D7FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

interface SchedulingListProps {
    items: Item[] | [];
    onCheckboxChange: (item: Item, isChecked: boolean) => void;
}

const SchedulingList: React.FC<SchedulingListProps> = (props: SchedulingListProps) => {
    const { items = [], onCheckboxChange } = props;
    const relevantItems = items.filter((item) => {
        return preScheduleStates.includes(item.status);
    });
    const groupedItems = _.groupBy(relevantItems, (item) => {
        return pendingSchedulingStates.includes(item.status) ? ItemGroups.Pending : ItemGroups.Valid;
    });

  const groupTitles: Record<keyof typeof ItemGroups, string> = {
    Valid: 'Approved items',
    Pending: 'Pending items',
  };

  const getEmptyGroup = (group: keyof typeof ItemGroups) => (
    <Stack
      key={group}
      alignItems={'center'}
    >
      <Typography
        color={'secondary.main'}
        variant="body1"
        textAlign={'center'}
      >
        {groupTitles[group]}
      </Typography>
      <EmptyMsgWrapper>
        <Typography
          key={group}
          color="secondary.main"
          variant="body1"
          textAlign="center"
          sx={{ mb: 3, mt: 3, maxWidth: '244px' }}
        >
          {EmptyStateMessages[group]}
        </Typography>
      </EmptyMsgWrapper>
    </Stack>
  );

  return (
    <SchedulingListWrapper>
      {([ItemGroups.Valid, ItemGroups.Pending] as const).map(group => (
        !groupedItems[group] ? (
          EmptyStateMessages[group] && getEmptyGroup(group)
        ) : (
          <Stack
            key={group}
            alignItems="center"
          >
            <Typography
              color="secondary.main"
              variant="body1"
              textAlign="center"
              sx={{ mb: 2 }}
            >
              {groupTitles[group]}
            </Typography>
              { groupedItems[group]?.map((item) => (
                  item.publicId.includes('temp') ? (
                      <ItemSkeleton
                          key={ item.publicId }
                          variant="rectangular"
                          animation="wave"
                      />
                  ) : (
                    <Box key={ item.publicId } mb={2}>
                    <Paper   elevation={0} sx={{p: 2}}>
                      <ItemCardDetails
                          item={ item  }
                          onCheckboxChange={ onCheckboxChange }
                      />
                    </Paper>
                    </Box>
                  )
              )) }
          </Stack>
        )
      ))}
    </SchedulingListWrapper>
  );
};

export default SchedulingList;
