import {
    SuccessContent,
} from 'src/components/SchedulingMethods/SchedulingMethods.styles.tsx';
import { Icon, IconButton, Typography } from '@worthy-npm/worthy-common-ui-components';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
    closeScheduling,
    getDropOffLocation,
    getScheduledDate, getSelectedItems,
    getSelectedShippingMethod, getStreet,
    getTimeRange, resetShippingState, setFlowToStart, setReset,
} from 'src/store/schedulingSlice';
import { formatDate } from 'src/helpers/common.ts';
import { setRoute } from 'src/store/sharedSlice';
import { useNavigate } from 'react-router-dom';
import { SchedulingType } from 'src/constants/item.constants.tsx';
import { Icon as CommonIcons } from '../Icons';
import { createTempShippingItem } from 'src/store/itemsSlice';
import { useEffect } from 'react';

const SuccessSchedule = ({ isPC }: { isPC: boolean }) => {
    const shipmentMethod = useAppSelector(getSelectedShippingMethod);
    const date = useAppSelector(getScheduledDate);
    const range = useAppSelector(getTimeRange);
    const location = useAppSelector(getDropOffLocation);
    const street = useAppSelector(getStreet);
    const selectedScheduled = useAppSelector(getSelectedItems);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setReset(true));
        const timer = setTimeout(() => {
            confirm();
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    const moveItem = () => {
        dispatch(createTempShippingItem({ publicIds: selectedScheduled, shipType: shipmentMethod, location: street }));
        dispatch(resetShippingState());
    };

    const confirm = () => {
        moveItem();
        dispatch(closeScheduling());
        dispatch(setRoute('/my-items'));
        navigate('/my-items');
    };

    const onClose = () => {
        moveItem();
        if (!isPC) {
            return dispatch(closeScheduling());
        }
        dispatch(setFlowToStart());
    };

    return (
        <>
            <IconButton
                variant="default"
                size="small"
                color="secondary"
                data-automation="schedule-close"
                onClick={ onClose }
                style={ { width: '24px', height: '24px', alignSelf: 'flex-end' } }
            >
                <Icon.CloseIcon style={ { width: '24px', height: '24px' } } />
            </IconButton>
            <CommonIcons.CheckCircleIcon style={ { width: '60px', height: '70px' } } />
            <SuccessContent>
                <Typography variant="h3" textAlign="center">
                    { shipmentMethod === SchedulingType.DROPOFF ? 'Drop-Off Successfully Selected' : 'Pickup Successfully Scheduled' }
                </Typography>
                {
                    shipmentMethod === SchedulingType.DROPOFF && (
                        <Typography variant="body1" textAlign="center">
                            The nearest FedEx location to your address is &nbsp;
                            { location }; however, you can drop your package off at any staffed FedEx location of your
                            choice.
                        </Typography>
                    )
                }
                {
                    shipmentMethod === SchedulingType.PICKUP && (
                        <Typography variant="body1" textAlign="center">
                            Your pickup is scheduled for { formatDate(date) }, <br />
                            between { range }.
                        </Typography>
                    )
                }
            </SuccessContent>
        </>
    );
};

export default SuccessSchedule;
