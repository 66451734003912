import { Box, Typography } from '@worthy-npm/worthy-common-ui-components';
import React from 'react';
import { CtaBtn } from 'src/styles/SchedulingCTA/SchedulingCTA.styles.tsx';

interface SchedulingCTAProps {
  disabled: boolean;
  itemsCount: number;
  onBtnClick: () => void;
}

const SchedulingCTA: React.FC<SchedulingCTAProps> = ({
  disabled,
  itemsCount,
  onBtnClick,
}: SchedulingCTAProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        padding: '12px 16px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        position: 'sticky',
        bottom: 0,
        left: '50%',
        zIndex: 1000,
        borderRadius: '4px 4px 0px 0px',
        background: '#FFF',
        boxShadow:
          '0px -42px 12px 0px rgba(0, 0, 0, 0.00), 0px -27px 11px 0px rgba(0, 0, 0, 0.01), 0px -15px 9px 0px rgba(0, 0, 0, 0.05), 0px -7px 7px 0px rgba(0, 0, 0, 0.09), 0px -2px 4px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            color: '#7A8994',
            fontFamily: 'Uncut Sans Variable',
            fontSize: '15px',
            fontWeight: '500',
            lineHeight: '21px',
            letterSpacing: '-0.3px',
          }}
        >
          {itemsCount} {itemsCount === 1 ? 'item' : 'items'}
        </Typography>
        <CtaBtn
          variant="contained"
          color="primary"
          disabled={disabled}
          sx={{
            color: '#FFF !important',
          }}
          onClick={onBtnClick}
        >
          Shipping Options
        </CtaBtn>
      </Box>
    </Box>
  );
};

export default SchedulingCTA;
