import React from 'react';
import {  DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';

interface ArrivedStepProps {
  item: Item;
}

const ArrivedStep: React.FC<ArrivedStepProps> = () => {

  return (
    <StyledStack>
      <DescriptionText>
        Soon we will start preparing your item for auction
      </DescriptionText>
    </StyledStack>
  );
};

export default ArrivedStep;
