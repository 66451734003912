import { Button, Paper, Stack, styled, Typography } from '@worthy-npm/worthy-common-ui-components';
import { getSubmissionUrl } from 'src/config';
import { redirectTo } from 'src/helpers/common';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.tritary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(2, 3),
    maxWidth: 500,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    width: 'fit-content',
    '&:hover': {
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white,
    },
}));
const MoreToSellSection = () => {
    return (
        <StyledPaper elevation={0}>
            <Stack
                gap={2}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography variant="h3">Have more items to sell?</Typography>
                <StyledButton
                    variant="outlined"
                    onClick={() => redirectTo(getSubmissionUrl())}
                >
                    Submit a New Item
                </StyledButton>
            </Stack>
        </StyledPaper>
    );
};

export default MoreToSellSection;
