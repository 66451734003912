import React from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import { getAppUrl } from 'src/config';

interface ReadyForAuctionStepProps {
  item: Item;
}

const ReadyForAuctionStep: React.FC<ReadyForAuctionStepProps> = ({item}) => {
  const onClick = () => {
    window.open(`${getAppUrl()}/item/${item.publicId}`); // 
  };
  return (
    <StyledStack>
      <DescriptionText>Set your reserve price and watch the offers roll in. Ready to start?</DescriptionText>
      <CtaButton onClick={onClick}>Start Live Auction</CtaButton>
    </StyledStack>
  );
};

export default ReadyForAuctionStep;
