import React, { useEffect, useMemo } from 'react';
import AddressInput from 'src/components/AddressInput/AddressInput.tsx';
import PickupSlots from 'src/components/PickupSlots/PickupSlots.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
    getSchedulingCurrentStep,
    setStepsLength,
    getSelectedShippingMethod,
} from 'src/store/schedulingSlice';
import { SchedulingType } from 'src/constants/item.constants';
import SuccessSchedule from './successSchedule';
import { PopUpContainer } from 'src/components/SchedulingMethods/SchedulingMethods.styles.tsx';
import SchedulingMethods from 'src/components/SchedulingMethods/SchedulingMethods.tsx';
import { getIsAllPending } from 'src/store/itemsSlice';

const SchedulingPopUp: React.FC<{isPC: boolean}> = ({isPC}) => {
    const currentStep: number = useAppSelector(getSchedulingCurrentStep);
    const selectedShippingMethod: '' | SchedulingType = useAppSelector(getSelectedShippingMethod);
    const allPending = useAppSelector(getIsAllPending);

    const dispatch = useAppDispatch();

    const steps = useMemo(() => {
        const commonSteps = [
            { component: <SchedulingMethods isPC={isPC} key="0" />, small: true, lessSpace: allPending },
            { component: <AddressInput isPC={isPC} key="1" />, small: true, lessSpace: false },
        ];

        if (selectedShippingMethod === SchedulingType.PICKUP) {
            commonSteps.push({ component: <PickupSlots isPC={isPC} key="2" />, small: false, lessSpace: false });
        }

        commonSteps.push({ component: <SuccessSchedule isPC={isPC} key="3"/>, small: false, lessSpace: false });

        return commonSteps;
    }, [ selectedShippingMethod ]);

    useEffect(() => {
        dispatch(setStepsLength(steps.length));
    }, [ dispatch, steps.length ]);

    return <PopUpContainer isPC={isPC} small={ steps[currentStep].small } lessSpace={ steps[currentStep].lessSpace }>{ steps[currentStep].component }</PopUpContainer>;
};

export default SchedulingPopUp;
