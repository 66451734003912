import React from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';

interface PrepForSaleStepProps {
  item: Item;
}

const PrepForSaleStep: React.FC<PrepForSaleStepProps> = () => {
  
  const onClick = () => {
    console.log('Clicked');
  }

  return (
    <StyledStack>
      <DescriptionText>
      Your item is being cleaned, graded, and photographed so it’s ready to shine.
      </DescriptionText>
      <CtaButton disabled onClick={onClick}>Start Live Auction</CtaButton>
    </StyledStack>
  );
};

export default PrepForSaleStep;
