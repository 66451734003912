import React, { useState } from 'react';
import {
  BasicModal,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Stack,
  Typography,
} from '@worthy-npm/worthy-common-ui-components';
import TextField from '@worthy-npm/worthy-common-ui-components/components/TextField';
import { ModalContainer, SubmitBtn } from 'src/components/CertificateModal/CertificateModal.styles.tsx';
import Ga from 'src/services/ga.service/ga.ts';
import { useAppDispatch } from 'src/store';
import { updateCertificate } from 'src/store/itemsSlice';

interface CertificateModalProps {
  open: boolean;
  itemPublicId: string;
  onClose: (open: boolean) => void;
}

const CertificateModal = ({ open, onClose, itemPublicId }: CertificateModalProps) => {
  const dispatch = useAppDispatch();

  const [certificate, setCertificate] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const submitCertificate = async () => {
    setLoading(true);
    setErrorMessage(null); // Clear previous error messages
    try {
      await dispatch(updateCertificate({ itemPublicId: itemPublicId, certificate: certificate })).unwrap();

      Ga.SendActionNavigate({
        event: 'interaction',
        interactionType: 'success',
        location1: 'app',
        location2: 'lightListing',
        location3: 'certificateModal',
        actionedObject: 'submitAddCertificateBtn',
        actionedValue: '',
        appVersion: '',
        buyerId: undefined,
      });

      onClose(false);
    } catch (error) {
      console.error("Error submitting certificate:", error);
      setErrorMessage("Failed to submit the certificate. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setCertificate('');
    setErrorMessage(null); // Reset the error message on close
    onClose(false);
  };

  const handleCertificateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setCertificate(value);
      const validCertificateNum = value.length >= 6 && value.length <= 10;
      setValid(validCertificateNum);
      setErrorMessage(null); // Clear error if input becomes valid
    }
  };

  return (
    <BasicModal
      open={open}
      onClose={() => onClose(false)}
    >
      <ModalContainer>
        <Grid
          gap={3}
          justifyContent={'flex-end'}
        >
          <Typography
            variant={'body1'}
            fontWeight={'bold'}
          >
            Upload GIA Report Details
          </Typography>
          <IconButton
            variant="default"
            data-automation="certificate-modal-close"
            style={{ width: '24px', height: '24px' }}
            onClick={handleClose}
          >
            <Icon.CloseIcon style={{ width: '24px', height: '24px' }} />
          </IconButton>
        </Grid>

        <Stack gap={1}>
          <Typography variant={'body1'}>
            If you know the GIA report number, please enter it
          </Typography>
          <TextField
            key="gia-number"
            id="gia-number"
            placeholder="Enter 6-10 digit number"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 10,
            }}
            fullWidth
            autoFocus
            variant="standard"
            value={certificate}
            onChange={handleCertificateChange}
            sx={{ paddingTop: '12px' }}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        </Stack>

        <SubmitBtn
          size="large"
          variant="contained"
          disabled={!valid || loading}
          onClick={() => { void submitCertificate(); }}
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
          <Typography
            variant="button"
            fontSize={'18px'}
          >
            Submit
          </Typography>
        </SubmitBtn>
      </ModalContainer>
    </BasicModal>
  );
};

export default CertificateModal;
