import styled from '@emotion/styled';
import { Box, Divider, Skeleton, Typography } from '@worthy-npm/worthy-common-ui-components';
import { CtaBtn } from 'src/styles/SchedulingCTA/SchedulingCTA.styles.tsx';

export const PopUpContainer = styled(Box, {
  shouldForwardProp: propName => ![ 'small', 'lessSpace','isPC'].includes(propName),
})<{ small?: boolean, lessSpace?: boolean, isPC?: boolean  }>(({ theme, small, lessSpace, isPC }) => ({
  height: small ? '420px' : '457px',
  display: 'flex',
  padding: '26px 16px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  marginTop: '52px',
  borderRadius: '8px',
  ...(isPC ? {}: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000,
    width: '100%',
    height: small ? '387px' : '447px',

    display: 'flex',
    padding: '26px 16px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: lessSpace ? theme.spacing(1) : theme.spacing(2),
    borderRadius: '16px 16px 0px 0px',
    background: theme.palette.background.paper,
    boxShadow:
        '0px -42px 12px 0px rgba(0, 0, 0, 0.00), 0px -27px 11px 0px rgba(0, 0, 0, 0.01), 0px -15px 9px 0px rgba(0, 0, 0, 0.05), 0px -7px 7px 0px rgba(0, 0, 0, 0.09), 0px -2px 4px 0px rgba(0, 0, 0, 0.10)',
  })
}));

export const ItemSkeleton = styled(Skeleton)(({ theme }) => ({
  marginLeft: '15px',
  maxWidth: '554px',
  width: '100%',
  height: '167px',
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  }
}));

export const ItemsCountWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`

export const ItemsCount = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Uncut Sans Variable',
  fontSize: '15px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '21px',
  letterSpacing: '-0.3px',
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '15px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '21px',
  alignSelf: 'flex-start',
}));

export const SchedulingMethodsContainer = styled(Box, {
  shouldForwardProp: propName => ![ 'empty' ].includes(propName),
})<{ empty?: boolean }>(({ theme, empty }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: empty ? theme.spacing(1) : theme.spacing(2),
  alignSelf: 'stretch',
}));

export const SchedulingListWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '602px',
  width: '100%',
  margin: '16px auto 0',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  }
}));

export const MethodWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flex: '1 0 0',
  alignSelf: 'stretch',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const PartnersWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
  [theme.breakpoints.up('md')]: {
    order: 1,
  }
}));

export const FloatingDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  }
}));

export const ItemCountInShipment = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
  [theme.breakpoints.up('md')]: {
    marginTop: '25px',
  }
}));

export const InsuranceText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '14px',
  letterSpacing: '-0.28px',
}));

export const Logos = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0px 8px',
  alignItems: 'center',
  gap: theme.spacing(3),
  alignSelf: 'stretch',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    marginLeft: '10px',
  }
}));

export const MethodsCtaBtn = styled(CtaBtn)(() => ({
  alignSelf: 'stretch',
  fontSize: '18px',
  flex: '0 1 0',
  padding: '8px',
}));

export const StarBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '68px',
    height: '43px',
    gap: '10px',
    color: theme.palette.tritary.main,
    marginTop: 'auto',
}));

export const SuccessContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    alignSelf: 'stretch',
    marginTop: '32px',
}));
