import styled from '@emotion/styled';
import { Box, IconButton, Typography } from '@worthy-npm/worthy-common-ui-components';
import Button from '@worthy-npm/worthy-common-ui-components/components/Button';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
  position: 'relative',
  flex: 1,
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
}));

export const NavIconBtn = styled(IconButton)(({ theme }) => ({
  width: '24px',
  height: '24px',
  color: theme.palette.secondary.main,
  zIndex: 10,
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  flex: '1 0 0',
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: 'normal',
}));

export const AddressContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
}));

export const AddressWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
  height: '40px',
}));

export const Address = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: theme.typography.body1.lineHeight,
  fontStyle: 'normal',
  letterSpacing: '-0.3px',
}));

export const SlotsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  padding: 2,
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none', // Hide scrollbar for WebKit browsers
  },
}));

export const Slot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const MonthWrapper = styled(Box, {
    shouldForwardProp: propName => ![ 'selected' ].includes(propName),
})<{ selected?: boolean }>(({ theme, selected }) => ({
    display: 'flex',
    width: '100px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '13px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing('6px', 1),
    gap: theme.spacing(1),
    color: selected ? theme.palette.highlight.main : theme.palette.text.primary,
    cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.background.default,
  }
}));

export const DayStr = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: 'normal',
  fontStyle: 'normal',
}));

export const MonthDayStr = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: 'normal',
}));

export const TimeWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  height: '180px',
  overflowY: 'scroll',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#aaa',
    borderRadius: '8px',
  },
}));

export const Time = styled(Box, {
    shouldForwardProp: propName => ![ 'selected' ].includes(propName),
})<{ selected?: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  minWidth: '118px',
  padding: '10px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
  borderRadius: '4px',
  backgroundColor: selected ? theme.palette.background.secondary :theme.palette.background.paper,
  color: selected ? theme.palette.text.secondary : theme.palette.text.primary,
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    backgroundColor: selected ? theme.palette.background.secondary :theme.palette.background.default,
    color: selected ? theme.palette.text.secondary : theme.palette.text.primary,
  }
}));

export const TimeStr = styled(Typography, {
  shouldForwardProp: propName => ![ 'selected' ].includes(propName),
})<{ selected?: boolean }>(({ theme, selected }) => ({
  color: selected ? theme.palette.text.secondary : theme.palette.text.primary,
  fontWeight: selected ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
}));

export const NoSlotsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

export const NoSlotsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '20px 6px 40px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

export const NoSlotsButton = styled(Button, {
  shouldForwardProp: propName => ![ 'small' ].includes(propName),
})<{ small?: boolean }>(({ theme, small }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: '4px',
  padding: '12px 16px 13px 16px',
  width: small ? '126px' : '230px',
}));

export const NavIcon = styled(IconButton, {
  shouldForwardProp: propName => ![ 'next' ].includes(propName),
})<{ next?: boolean }>(({ theme, next }) => ({
  position: 'absolute',
  bottom: '66%',
  height: '26px',
  backgroundColor: theme.palette.background.default,
  boxShadow: `1px 1px 8px 6px ${ theme.palette.background.default }`,
  ...(next ? { right: '0' } : { left: '0' }),
  '&:hover': {
    backgroundColor: theme.palette.background.default
  },
  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `1px 1px 8px 6px ${ theme.palette.background.paper }`,
    bottom: '65%',
    ...(next ? { right: '13px' } : { left: '13px' }),

    '&:hover': {
      backgroundColor: theme.palette.background.paper
    },
  },
}));
