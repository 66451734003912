import styled from '@emotion/styled';
import Button from '@worthy-npm/worthy-common-ui-components/components/Button';

export const CtaBtn = styled(Button)(({theme}) => ({
  borderRadius: '4px',
  background: theme.palette.highlight.main,
  display: 'flex',
  padding: '12px 16px 13px 16px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flex: '1 0 0',
}));

export const BottomFixedCtaBtn = styled(Button)(({theme}) => ({
  borderRadius: '4px',
  background: theme.palette.highlight.main,
  display: 'flex',
  padding: '12px 16px 13px 16px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flex: '0 1 auto',
  marginTop: 'auto',
}));
