import axios from 'axios';
import { LocationAddress } from 'src/types/shipping.ts';
import { getGoogleApiKey } from 'src/config';

export interface GoogleAddressResponse {
    results: { formatted_address: string }[];
}

export const getAddressFromGoogle = async (
    location: LocationAddress,
): Promise<GoogleAddressResponse> => {
    const apiKey = getGoogleApiKey();
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${location.StreetLines},${location.City},${location.StateOrProvinceCode}&key=${apiKey}`;
    const res = await axios.get<GoogleAddressResponse>(url, {
        withCredentials: false,
    });

    return res.data;
};
