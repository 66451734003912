import React from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import { getContentSiteUrl } from 'src/config';

interface AuctionEndedStepProps {
  item: Item;
}

const AuctionEndedStep: React.FC<AuctionEndedStepProps> = ({item}) => {
  const onclick = () => {
    window.open(`${getContentSiteUrl()}/items/${item.publicId}`);

  };
  return (
    <StyledStack>
      <DescriptionText>View the results of your auction now.</DescriptionText>
      <CtaButton onClick={onclick}>See Auction Results</CtaButton>
    </StyledStack>
  );
};

export default AuctionEndedStep;
