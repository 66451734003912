import * as React from 'react';
import { IconButton, Popover, Stack, Typography } from '@worthy-npm/worthy-common-ui-components';
import { Icon as CommonIcons } from 'src/components/Icons.tsx';
import { useState } from 'react';

const ClickableHelpTooltip: React.FC<{ title: string, large?: boolean }> = ({ title, large = false }) => {
  const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
    setAnchorEl(null);
  };

  const handleTooltipClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    const anchor = openTooltip ? null : event.currentTarget;
    setOpenTooltip(!openTooltip);
    setAnchorEl(anchor);
  };

  return (
    <>
      <IconButton
        id="help-button"
        onClick={handleTooltipClicked}
        aria-haspopup="true"
        style={{
          padding: 0,
          cursor: 'pointer',
        }}
      >
        <CommonIcons.HelpIcon
            sx={{ color: 'divider', width: large ? '20px' : '14px', height: large ? '20px' : '14px' }}
        />
      </IconButton>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={openTooltip}
        onClose={handleTooltipClose}
        anchorEl={anchorEl}
      >
        <Stack
          padding={2}
          gap={2}
          maxHeight={'180px'}
          maxWidth={'300px'}
          justifyContent={'center'}
          borderRadius={'4px'}
          border={'1.5px solid #D2D5D7'}
        >
          <Typography variant={'body1'}>
            {title}
          </Typography>
        </Stack>
      </Popover>
    </>
  );
};

export default ClickableHelpTooltip;
