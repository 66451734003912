import ThemeProvider from '@worthy-npm/worthy-common-ui-components/styles/ThemeProvider';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@worthy-npm/worthy-common-ui-components';
import router from './routes';
import { store } from './store';

// Import services to initialize them
import 'src/services/ga.service';
import 'src/services/rollbar.service';
import { AuthProvider } from 'src/context/authContext.tsx';

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider theme={worthyShineTheme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
