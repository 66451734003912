import { PayloadAction } from '@reduxjs/toolkit';
import { Profile, backendService } from 'src/services/api/backend.service.ts';
import { createAppSlice } from 'src/store/createAppSlice.ts';
import { isFakePhone } from 'src/helpers/common.ts';

interface UserState {
  user: Profile | Partial<Profile>;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: {
    userId: 0,
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    address: '',
    itemsCount: 0,
  },
  loading: false,
  error: null,
};

export const userSlice = createAppSlice({
  name: 'user',
  initialState,
  reducers: (create) => ({
    setUserProfile: create.reducer((state, action: PayloadAction<Profile | Partial<Profile>>) => {
      state.user = action.payload;
    }),
    fetchUserProfile: create.asyncThunk<Profile | Partial<Profile>>(
      async () => {
        return backendService.fetchUserProfile();
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        fulfilled: (state, action: PayloadAction<Profile | Partial<Profile>>) => {
          state.loading = false;
          state.user = action.payload;
        },
        rejected: (state) => {
          state.loading = false;
          if (state.user.userId) return;

          state.user = initialState.user;
        },
      },
    ),
  }),

  selectors: {
    getUser: (state) => state.user,
    getItemCount: (state) => state.user.itemsCount,
    selectLoading: (state) => state.loading,
    selectError: (state) => state.error,
    getUserId: (state) => state.user.userId,
    isUserMissingPhoneNumber: (state) =>
      !state.user.phoneNumber || isFakePhone(state.user.phoneNumber),
  },
});

export const { isUserMissingPhoneNumber, getUser, getItemCount, getUserId } = userSlice.selectors;
export const { fetchUserProfile, setUserProfile } = userSlice.actions;
