import React from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import {  getContentSiteUrl } from 'src/config';

interface LiveAuctionStepProps {
  item: Item;
}


const LiveAuctionStep: React.FC<LiveAuctionStepProps> = ({item}) => {
  const onclick = () => {
    window.open(`${getContentSiteUrl()}/items/${item.publicId}`);
  };
  return (
    <StyledStack>
      <DescriptionText>Visit your live auction page to watch.</DescriptionText>
      <CtaButton onClick={onclick}>See Live Auction</CtaButton>
    </StyledStack>
  );
};

export default LiveAuctionStep;
